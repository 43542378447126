import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { MembershipTypeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import { AppUI } from '@seedlang/state';

@observer
class SiteMembershipTypeEdit extends React.Component {

  componentDidMount() {
    this.loadMembershipType();
    this.loadDiscordRoles();
    this.loadPatreonRewards();
  }

  @autobind loadMembershipType() {
    MembershipTypeStore.getShow({ids: {membershipTypeId: this.props.params.membershipTypeId}});
  }

  @autobind loadDiscordRoles() {
    AppUI.discordRoleStore.getIndex();
  }

  @autobind loadPatreonRewards() {
    AppUI.patreonRewardStore.getIndex();
  }

  render() {
    return (
      <div className='membership-type-edit'>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.name}
                field="name"
                model="membership_types"
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Discord Role</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipTypeStore.showData.id}
                model="membership_types"
                field="discord_role_id"
                options={AppUI.discordRoleStore.optionsForSelect}
                value={MembershipTypeStore.showData.discordRoleId}
                afterChange={this.loadMembershipType}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Patreon Tier/Reward</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipTypeStore.showData.id}
                model="membership_types"
                field="patreon_reward_id"
                options={AppUI.patreonRewardStore.optionsForSelect}
                value={MembershipTypeStore.showData.patreonRewardId}
                afterChange={this.loadMembershipType}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Image</legend>
              {
                MembershipTypeStore.hasShowData &&
                  <InPlaceImageUpload
                    model="images"
                    image={MembershipTypeStore.showData.image}
                    onChange={this.loadMembershipType}
                    querystring={{
                      imageable_id: MembershipTypeStore.showDataField('id'),
                      imageable_type: 'MembershipType'
                    }}
                  />
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description (Markdown)</legend>
              <InPlaceText
                markdown
                inputType="textarea"
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.descriptionMd}
                field="description_md"
                model="membership_types"
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description (HTML)</legend>
              <InPlaceText
                richText
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.description}
                field="description"
                model="membership_types"
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary(SiteMembershipTypeEdit);
