import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { UserGroupStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class UserCreate extends React.Component {
  @observable showCreatedMessage = false;
  @observable message = '';
  @observable testAccount = false;

  @autobind onCreate() {
    UserGroupStore.addUser(
      {
        data: {
          email: this.refs.email.value,
          password: this.refs.password.value,
          passwordConfirmation: this.refs.password.value,
          test_account: this.testAccount,
        },
      },
    this.afterCreate);
  }

  @autobind afterCreate() {
    this.refs.email.value = '';
    this.refs.password.value = '';
    this.showCreatedMessage = true;
    this.message = "A user was successfully added.";
  }

  render() {
    return (
      <div className="concept-category-create">
        {
          this.showCreatedMessage &&
            <div className='alert'>
              {this.message}
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'><h3>Create One</h3></div>
        </div>
        <div className='row'>
          <div className="col-xs-6">
            <input
              placeholder="Add a email"
              ref="email"
            />
          </div>
          <div className="col-xs-6">
            <input
              placeholder="Add a password"
              ref="password"
            />
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12">
            <InPlaceCheckbox
              onChange={() => this.testAccount = !this.testAccount}
              checked={this.testAccount}
            >
              Test Account
            </InPlaceCheckbox>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12">
            <button
              className="button-primary"
              onClick={this.onCreate}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserCreate;
