import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { ExerciseStore, ExerciseTypeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import ExerciseEntryCreate from 'pages/creator/exercise_entry/exercise_entry_create';
import ExerciseEntryIndex from 'pages/creator/exercise_entry/exercise_entry_index';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import styled from '@emotion/styled';
import { isPresent, isBlank } from "@seedlang/utils";
import Modal from "components/modal";
import { AppUI } from "@seedlang/state";
import Alert from "components/alert";
import Filter from "components/backend_filter/filter";
import InfoTooltip from "components/info_tooltip";
const Wrapper = styled.div`
  .exercise-index {
    .exercise-entry-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .fa-angle-right {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
      .exercise-entry-index {
        flex: 1;
      }
    }
    .colspan {
      padding: 0!important;
    }
    .exercise-input-table {
      table {
        margin-top: 0;
      }
    }
  }
`;

@observer
class ExerciseIndex extends React.Component {
  @observable moveExerciseModalId;
  @observable exerciseMovedToWorksheet;

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.worksheetId !== prevProps.worksheetId) {
      this.exerciseMovedToWorksheet = null;
      this.moveExerciseModalId = null;
    }
  }

  @computed get exercises() {
    return this.props.exercises || ExerciseStore.indexData;
  }

  @computed get groupId() {
    return this.props.groupId || (this.props.params && this.props.params.groupId);
  }

  componentDidMount() {
    if (!this.props.worksheetId && !this.props.inlineEntries) {
      ExerciseStore.getIndex();
    }
    if (!ExerciseTypeStore.hasIndexData) {
      ExerciseTypeStore.getIndex();
    }
  }

  @autobind afterOnDelete() {
    ExerciseStore.getIndex();
    if (this.props.afterUpdate) {
      this.props.afterUpdate();
    }
  }

  @autobind onDelete(id) {
    ExerciseStore.destroy({ids: {exerciseId: id}}, this.afterOnDelete);
  }

  @computed get moveExerciseName() {
    return this.exercises.find(item => item.id === this.moveExerciseModalId)?.name;
  }

  @autobind showMoveExerciseModal(exercise) {
    this.moveExerciseModalId = exercise.id;
  }

  @autobind moveExercise(worksheet) {
    ExerciseStore.update({ids: {exerciseId: this.moveExerciseModalId}, data: {worksheetId: worksheet?.id}}, (resp) => this.afterMoveExercise(resp, worksheet));
  }

  @autobind afterMoveExercise(resp, worksheet) {
    this.moveExerciseModalId = null;
    this.afterOnDelete();
    this.exerciseMovedToWorksheet = worksheet;
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.moveExerciseModalId) &&
          <Modal
            onCloseModal={() => this.moveExerciseModalId = null}
            title={`Move "${this.moveExerciseName}" to...`}
            background={AppUI.site.accentColor}
            gradient={isBlank(AppUI.site.accentColor)}
          >
            <Filter
              store={AppUI.worksheetStore}
              limit={10}
              defaultFilters={{archived: false}}
              filters={[
                {
                  type: 'text',
                  name: 'name',
                  label: 'Name',
                  placeholder: 'Enter Name',
                  default: ''
                },
              ]}
              queryStrings={{include_count: true, show_all: true, expanded: true}}
            />
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th>Name</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                  {
                    AppUI.worksheetStore.hasIndexData && AppUI.worksheetStore.indexData?.map(item => {
                      if (item.id !== this.props.worksheetId) {
                        return (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td><button className='button-primary' onClick={() => this.moveExercise(item)}>Select</button></td>
                          </tr>
                        )
                      }
                    })
                  }
                </tbody>
            </table>
            <Paginator
              store={AppUI.worksheetStore}
              limit={10}
            />
          </Modal>
        }
        {
          isBlank(this.props.worksheetId) && !this.props.inDeck &&
            <Filter
              store={ExerciseStore}
              // queryStrings={{include_count: true, show_all: true, expanded: true}}
              filters={
                [
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Name',
                    placeholder: 'Enter Name',
                    default: ''
                  },
                  {
                    type: 'select',
                    name: 'exercise_type_id',
                    options: ExerciseTypeStore.indexData.map(item => ({id: item.id, name: item.name})),
                    labelField: 'name',
                    placeholder: 'Select Exercise Type',
                    label: 'Exercise Type',
                    default: '',
                  },
                ]
              }
          />
        }
        <div className='exercise-index'>
          {
            isPresent(this.exerciseMovedToWorksheet) &&
            <Alert>Exercise moved to <Link to={{ name: 'creator.groups.worksheets.edit', params: { groupId: this.groupId, worksheetId: this.exerciseMovedToWorksheet?.id } }} onlyActiveOnIndex>{this.exerciseMovedToWorksheet?.name}</Link></Alert>
          }
          <table className="table-wrapper admin-table">
            <thead>
              <tr>
                {
                  !this.props.inlineEntries &&
                    <th width="20" />
                }
                {
                  this.props.showPosition &&
                    <th>Pos</th>
                }
                {
                  !this.props.worksheetId &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('created_at'))}
                      onClick={() => this.props.onSortColumn('created_at')}
                    >
                      Created
                    </th>
                }
                {
                  !this.props.inlineEntries &&
                    <th>Name</th>
                }
                <th width="100px" >Type</th>
                {
                  this.props.inlineEntries &&
                    <th>Entry</th>
                }
                {
                  !this.props.inlineEntries &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('exercises.users_started_count'))}
                      onClick={() => this.props.onSortColumn('exercises.users_started_count')}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                        Started
                      </div>
                      <InfoTooltip margin="-5px 0 0 3px">The number of times this exercise has been started.</InfoTooltip>
                      </div>
                    </th>
                }
                {
                  !this.props.inlineEntries &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('exercises.users_percentage_finished'))}
                      onClick={() => this.props.onSortColumn('exercises.users_percentage_finished')}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                        Finished
                      </div>
                      <InfoTooltip margin="-5px 0 0 3px">The percentage of times this exercise has been finished.</InfoTooltip>
                      </div>
                    </th>
                }
                {
                  !this.props.inlineEntries &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('exercises.average_attempts_count'))}
                      onClick={() => this.props.onSortColumn('exercises.average_attempts_count')}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                        Attempted
                      </div>
                      <InfoTooltip margin="-5px 0 0 3px">The average number of attempts this exercise has attempted.</InfoTooltip>
                      </div>
                    </th>
                }
                {
                  !this.props.inlineEntries &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('exercises.ratings_count'))}
                      onClick={() => this.props.onSortColumn('exercises.ratings_count')}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                        Ratings
                      </div>
                      <InfoTooltip margin="-5px 0 0 3px">The number of ratings this exercise has received.</InfoTooltip>
                      </div>
                    </th>
                }
                {
                  !this.props.inlineEntries &&
                    <th
                      className={cx('sortable', this.props.columnClassNames('exercises.ratings_average'))}
                      onClick={() => this.props.onSortColumn('exercises.ratings_average')}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                      <div>
                        Average
                      </div>
                      <InfoTooltip margin="-5px 0 0 3px">The average rating this exercise has received.</InfoTooltip>
                      </div>
                    </th>
                }
                <th width="40"></th>
              </tr>
            </thead>
            <tbody>
              {
                this.exercises && this.exercises.map((item) => {
                  return (
                    <tr
                      key={item.id}
                    >
                      {
                        !this.props.inlineEntries && this.groupId && (this.props.worksheetId || item.worksheetId) &&
                          <td>
                            <Link
                              to={{ name: 'creator.groups.exercises.edit', params: { worksheetId: this.props.worksheetId || item.worksheetId, groupId: this.groupId, exerciseId: item.id } }}
                              className="on-click"
                            >
                              <i className="fa fa-chevron-right fa-block" />
                            </Link>
                          </td>
                      }
                      {
                        !this.props.inlineEntries && !this.props.worksheetId && !item.worksheetId &&
                          <td />
                      }
                      {
                        this.props.showPosition &&
                          <td>
                            <div className='text'>
                              <InPlaceText
                                defaultValue={item.position}
                                model="exercises"
                                field="position"
                                id={item.id}
                                type="number"
                                step="1"
                                afterChange={this.props.afterCreate}
                              />
                            </div>
                          </td>
                      }
                      {
                        !this.props.worksheetId &&
                          <td>
                            {item.createdAt.formatted}
                          </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            <div className='text'>
                              <InPlaceText
                                defaultValue={item.name}
                                model="exercises"
                                field="name"
                                id={item.id}
                                afterChange={this.props.afterCreate}
                              />
                            </div>
                            {
                              !this.props.worksheetId && item.worksheet && item.worksheet.id &&
                                  <div className='text'>
                                    {
                                      this.groupId &&
                                        <Link
                                          to={{ name: 'creator.groups.worksheets.edit', params: { worksheetId: item.worksheetId, groupId: this.groupId } }}
                                        >
                                          {item.worksheet.name}
                                        </Link>
                                    }
                                  </div>
                            }
                          </td>
                      }
                      <td>
                        <div className='text'>
                          {item.exerciseTypeName}
                        </div>
                      </td>
                      {
                        this.props.inlineEntries &&
                            <td>
                              {
                                item.exerciseEntriesCount === 0 &&
                                  <ExerciseEntryCreate
                                    exercise={item}
                                    afterCreate={this.props.afterCreate}
                                    languageId={this.props.languageId}
                                  />
                              }
                              {
                                item.exerciseEntriesCount > 0 &&
                                  <ExerciseEntryIndex
                                    languageId={this.props.languageId}
                                    hideTableHeader
                                    hidePosition
                                    allowTargetInput={this.props.allowTargetInput}
                                    exercise={item}
                                    exerciseType={item.exerciseType}
                                    afterUpdate={this.props.afterCreate}
                                    inDeck={this.props.inDeck}
                                  />
                              }
                            </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            {item.exerciseEntriesCount > 0 && item.usersStartedCount}
                          </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            {item.usersPercentageFinished}%
                          </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            {item.exerciseEntriesCount > 0 && item.averageAttemptsCount}
                          </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            {item.ratingsCount}
                          </td>
                      }
                      {
                        !this.props.inlineEntries &&
                          <td>
                            {item.ratingsAverage}
                          </td>
                      }
                      <td style={{display: 'flex', flexDirection: 'column', gap: '3px'}}>
                        <DeleteButton
                          onConfirm={() => this.showMoveExerciseModal(item)}
                          message="Move to another worksheet?"
                          className="fa-block right"
                          faIcon='fa-share'
                        />
                        <DeleteButton
                          onConfirm={() => this.onDelete(item.id)}
                          message="Delete this exercise?"
                          className="fa-block right"
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {
            !this.props.worksheetId &&
              <Paginator
                store={ExerciseStore}
              />
          }
        </div>
      </Wrapper>
    );
  }
}

export default SortableColumns(ExerciseIndex, ExerciseStore, {
  sortColumn: 'created_at',
  sortIsAscending: false,
  filters: {worksheet_id: true, exercise_entries_count: '!0'},
});
