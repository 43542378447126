import React from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { isPresent } from '@seedlang/utils';
import { WorksheetStore, LanguageStore, LevelStore, WordTypeStore, WorksheetWordStore } from '@seedlang/stores';
import SortableColumns from 'components/hoc/sortable_columns';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme, Constants } from '@seedlang/constants';
import WorksheetWordIndexRow from "pages/creator/worksheets/worksheet_word_index_row";
import { AppUI } from "@seedlang/state";
import WordStructureModal from "pages/creator/worksheets/word_structure_modal";
import InfoTooltip from "components/info_tooltip";
import Button from "components/button/button";

const Wrapper = styled.div`
  .word-index {
    .prefix-wrapper {
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      width: 60px;
      .text {
        display: block;
      }
      .remove {
        display: none;
      }
      .fa-download {
        color: ${Theme.red};
        font-size: 12px;
      }
    }
    .prefix-wrapper:hover {
      .text {
        display: none;
      }
      .remove {
        text-decoration: underline;
        display: block;
      }
    }
  }
`;

@observer
class WorksheetWordIndex extends React.Component {

  @observable verbStructureWorksheetWordId;

  componentDidMount() {
    this.verbStructureWorksheetWordId = null;
    if (!LevelStore.hasIndexData) {
      LevelStore.getIndex();
    }
    if (!WordTypeStore.hasIndexData) {
      WordTypeStore.getIndex();
    }
    if (AppUI.site.languageId) {
      LanguageStore.getShow({ids: {languageId: AppUI.site.languageId}});
    }
  }

  componentWillUnmount () {
    this.verbStructureWorksheetWordId = null;
  }

  @autobind onDelete(id) {
    WorksheetWordStore.destroy({ids: {worksheetWordId: id}}, this.props.afterChange);
  }

  @autobind toggleVerbStructureModal(worksheetWord) {
    if (isPresent(this.verbStructureWorksheetWord)) {
      this.verbStructureWorksheetWordId = null;
    } else {
      this.verbStructureWorksheetWordId = worksheetWord.id;
    }
  }

  @computed get verbStructureWorksheetWord() {
    return WorksheetWordStore.hasIndexData && WorksheetWordStore.indexData.find(item => item.id === this.verbStructureWorksheetWordId);
  }

  @computed get registerOptions() {
    let registerOptions = Constants.REGISTER_OPTIONS;
    if (AppUI.site.languageId === 'DE' || AppUI.siteIsDefault) {
        registerOptions.push(['austrian','Austrian']);
        registerOptions.push(['swiss','Swiss']);
    } return registerOptions;
  }


  render() {
    return (
      <Wrapper>
        {
          isPresent(this.verbStructureWorksheetWordId) && isPresent(this.verbStructureWorksheetWord) &&
            <WordStructureModal
              worksheetWord={this.verbStructureWorksheetWord}
              toggleModal={this.toggleVerbStructureModal}
              afterChange={this.props.afterChange}
            />
        }
        <div className="word-index">
          <table className="table-wrapper admin-table">
            <thead>
            <tr>
              {
                !this.props.hidePositions &&
                  <th width="15"># <InfoTooltip>Chronological Positions</InfoTooltip></th>
              }
              <th width="15" />{/* External Info */}
              <th width="50">Type</th>
              <th width="100">Target</th>
              {
                AppUI.site.showAlternateTargetText &&
                <th width="100">Romanized</th>
              }
              {
                !AppUI.site.languageId?.startsWith('EN') &&
                  <th width="120">Source</th>
              }
              {
                AppUI.site.languageId?.startsWith('EN') &&
                  <th width="350">Definition</th>
              }
              <th width="60">Plural</th>
              {/*<th*/}
              {/*  width="100"*/}
              {/*  data-tip*/}
              {/*  data-for="gender-var-heading"*/}
              {/*>*/}
              {/*  <span>Gender var.</span>*/}
              {/*  <InfoTooltip*/}
              {/*    margin="1px 0 0px 5px"*/}
              {/*  >*/}
              {/*    For example in German, <i>Schülerin</i> is the gender variant of <i>Schüler</i>.*/}
              {/*  </InfoTooltip>*/}
              {/*</th>*/}
              {
                isPresent(LanguageStore.showData.genderOptions) &&
                  <th width="50">Gender</th>
              }
              <th width="60">Register</th>
              {
                !AppUI.siteIsDefault && !AppUI.site.language?.hasLessons &&
                  <th width="100">Tags</th>
              }
              <th width="100">Level</th>
              {
                (AppUI.siteIsDefault || LanguageStore.showData.hasLessons) &&
                  <th width="80">Has Video</th>
              }
              <th width="16" />
            </tr>
            </thead>
            <tbody>
            {
              WorksheetWordStore.indexData.map((item, index) => {
                return (
                  <WorksheetWordIndexRow
                    key={`${item.id}-${index}`}
                    {...this.props}
                    highlighted={isPresent(this.props.highlightedWordIds) ? this.props.highlightedWordIds.indexOf(item.word.id) !== -1 : false}
                    worksheetWord={item}
                    afterChange={this.props.afterChange}
                    onDelete={this.onDelete}
                    genderOptions={LanguageStore.showData.genderOptions}
                    showAlternateTargetText={AppUI.site.showAlternateTargetText}
                    toggleVerbStructureModal={this.toggleVerbStructureModal}
                    showHasVideo={AppUI.siteIsDefault || LanguageStore.showData.hasLessons}
                    registerOptions={this.registerOptions}
                  />
                )
              })
            }
            </tbody>
          </table>
        </div>
      </Wrapper>
    );
  }
}

export default SortableColumns(WorksheetWordIndex, WorksheetWordStore, { sortColumn: 'position', sortIsAscending: true });
