import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LanguageStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import { LanguageIcon } from '@seedlang/icons';
import { Link } from "react-router";
import Filter from "components/backend_filter/filter";
const Wrapper = styled.div`

`;

@observer
class LanguageIndex extends React.Component {

  componentDidMount() {
    LanguageStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <Filter
          store = {LanguageStore}
          defaultSort = "name"
          namespace = "language-index"
          filters = {
            [
              {
                type: 'select',
                options: [{id: 'qwen', name: 'Qwen'}, {id: 'open_ai', name: 'OpenAI'}, {id: 'google', name: 'Google'}],
                name: 'translation_client_words',
                label: 'Words Translation Client',
                labelField: 'name',
                placeholder: 'Words Translation Client',
                default: '',
              },
              {
                type: 'select',
                options: [{id: 'qwen', name: 'Qwen'}, {id: 'open_ai', name: 'OpenAI'}, {id: 'google', name: 'Google'}],
                name: 'translation_client_sentences',
                label: 'Sentences Translation Client',
                labelField: 'name',
                placeholder: 'Sentences Translation Client',
                default: '',
              },
              {
                type: 'text',
                name: 'name',
                label: 'name',
                placeholder: 'Name',
                default: '',
              },
              {
                type: 'boolean',
                name: 'has_lessons',
                label: "is Seedlang language"
              },
            ]
          }
        />
        <table className = "table-wrapper admin-table">
          <thead>
            <tr>
              <th />
              <th />
              <th
                className = {cx('sortable', this.props.columnClassNames('id'))}
                onClick = {() => this.props.onSortColumn('id')}
              >
                ID
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('name'))}
                onClick = {() => this.props.onSortColumn('name')}
              >
                Name
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('users_count'))}
                onClick = {() => this.props.onSortColumn('users_count')}
              >
                # Users
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('paid_users_count'))}
                onClick = {() => this.props.onSortColumn('paid_users_count')}
              >
                # Pros
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('sources_count'))}
                onClick = {() => this.props.onSortColumn('sources_count')}
              >
                # Sources
              </th>
            </tr>
          </thead>
          <tbody>
            {
              LanguageStore.indexData.map((item) => {
                return (
                  <tr
                    key = {item.id}
                  >
                    <td>
                      <Link
                        to = {{ name: 'builder.languages.edit', params: { languageId: item.id } }}
                        className = "on-click"
                      >
                        <i className = "fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <LanguageIcon
                        languageId = {item.id}
                      />
                    </td>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.usersCount}</td>
                    <td>{item.paidUsersCount}</td>
                    <td>{item.sourcesCount}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store = {LanguageStore}
        />
      </Wrapper>
    );
  }
}

export default SortableColumns(LanguageIndex, LanguageStore, { sortColumn: 'name', sortIsAscending: true });