import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { UserStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import UserProfileImage from 'components/user/user_profile_image';
import Filter from 'components/backend_filter/filter';
import UserCreate from 'pages/builder/users/user_create';
import Modal from 'components/modal';
import CheckIfTrue from 'components/check_if_true';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';
import autobind from 'autobind-decorator';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`
  .user-index {
    .gray-button {
      margin-bottom: 10px;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
`;

@observer
class UserIndex extends React.Component {
  @observable showCreate = false;
  @observable showCopiedMessage = false;

  @computed get store() {
    return this.props.store || UserStore;
  }

  componentDidMount() {
    AppUI.membershipGroupStore.getIndex({})
  }

  componentWillUnmount() {
    this.store.clearIndexData();
  }

  onDelete(id) {
    this.store.destroy({ ids: { userId: id } });
  }

  @autobind copyEmailAddresses() {
    this.showCopiedMessage = true;
    setTimeout(() => this.showCopiedMessage = false, 3000);
  }

  render() {
    return (
      <Wrapper>
        <div className="user-index user-index-page">
          {
            this.showCopiedMessage &&
              <Alert>
                Email addresses were copied
              </Alert>
          }
          {
            this.showCreate &&
              <Modal
                onCloseModal={() => this.showCreate = false}
              >
                <UserCreate />
              </Modal>
          }
          <FilterWrapper>
            <Filter
              store={this.store}
              defaultSort="-last_active_at"
              namespace="user-index"
              queryStrings={{include_count: true}}
              filters={
                [
                  {
                    type: 'text',
                    name: 'name',
                    label: 'name',
                    placeholder: 'Name',
                    default: '',
                  },
                  {
                    type: 'text',
                    name: 'email',
                    label: 'email',
                    placeholder: 'Email',
                    default: '',
                  },
                  {
                    type: 'text',
                    name: 'paypal_email',
                    placeholder: 'Paypal Email',
                    label: 'Paypal Email',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'guest',
                    label: 'Guest',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'admin',
                    label: 'Admin',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'translator',
                    label: 'Translator',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'moderator',
                    label: 'Moderator',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'membership_type_id',
                    label: 'Paying Subscriber',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'permanent_membership',
                    label: 'Permanent Subscriber',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'contacted_for_interview',
                    label: 'Contacted For Interview',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'language_signed_up_with',
                    label: 'Language Signed Up With',
                    labelField: 'name',
                    options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'device_signed_up_with',
                    label: 'Device Signed Up With',
                    labelField: 'name',
                    options: [{id: 'android', name: 'Android'}, {id: 'ios', name: 'iOS'}, {id: 'web', name: 'Web'}],
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'membership_group_id',
                    label: 'Membership Group',
                    labelField: 'name',
                    options: AppUI.membershipGroupStore.indexData.filter(item => item.usersCount > 0).map(item => ({id: item.id, name: item.name})),
                    default: '',
                  }
                ]
              }
            />
          </FilterWrapper>
          <button
            className='gray-button'
            onClick={() => this.showCreate = true}
          >
            <i className='fa fa-plus' />
            Create Users
          </button>
          <CopyToClipboard
            text={AppUI.userStore.indexData.map(item => item.email).join(",")}
            onCopy={this.copyEmailAddresses}
          >
            <button
              style={{marginLeft: '10px'}}
              className='gray-button'
            >
              <i className='fa fa-copy' />
              Copy Email Addresses
            </button>
          </CopyToClipboard>
          {
            this.store.requestCounter > 0 &&
              <Spinner
              />
          }
          {
            this.store.requestCounter === 0 &&
              <table className="table-wrapper">
                <thead>
                  <tr>
                    <th></th>
                    <th
                      className={cx('sortable', this.props.columnClassNames('last_active_at'))}
                      onClick={() => this.props.onSortColumn('last_active_at')}
                    >
                      Active
                    </th>
                    <th
                      className={cx('sortable', this.props.columnClassNames('created_at'))}
                      onClick={() => this.props.onSortColumn('created_at')}
                    >
                      Created
                    </th>
                    <th>
                      Name
                    </th>
                    <th>
                      Promo
                    </th>
                    <th>
                      Img
                    </th>
                    <th>
                      Level
                    </th>
                    <th>
                      Grp
                    </th>
                    <th>
                      Strk
                    </th>
                    <th>
                      XP
                    </th>
                    <th>
                      Rvw
                    </th>
                    <th>
                      Decks
                    </th>
                    <th>
                      Device
                    </th>
                    <th>
                      Mem Group
                    </th>
                    <th>
                      Last Deck
                    </th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.store.indexData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={{ name: 'builder.users.edit', params: { userId: item.id } }}
                              className="on-click"
                            >
                              <i className="fa fa-chevron-right fa-block" />
                            </Link>
                          </td>
                          <td>
                            <div className="text">{item.lastActiveAt.relativeTimeShort}</div>
                          </td>
                          <td>
                            <div className="text">
                              {item.createdAt?.relativeTimeShort}
                            </div>
                          </td>
                          <td>
                            <Link
                              to={{ name: 'builder.users.edit', params: { userId: item.id } }}
                              className="on-click"
                            >
                              <div className='text'>{item.name}</div>
                            </Link>
                            <div className='text'>{item.email}</div>
                          </td>
                          <td>
                            {item.signedUpWithPromotion && item.signedUpWithPromotion.slug}
                          </td>
                          <td>
                            <div className="text">
                              <UserProfileImage
                                clickToProfile
                                width={60}
                                user={item}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="text">
                              {item.levelAbbreviation}
                            </div>
                          </td>
                          <td>
                            <CheckIfTrue
                              value={item.userGroupsCount > 0}
                            />
                          </td>
                          <td>
                            {item.currentStreakCount}
                          </td>
                          <td>
                            {item.xp}
                          </td>
                          <td>
                            {item.pastDueReviewCardsCount}/{item.reviewCardsCount}
                          </td>
                          <td>
                            <div className="text">
                              {item.finishedUserDecksCount}/{item.userDecksCount}
                            </div>
                          </td>
                          <td>
                            {item.deviceSignedUpWith}
                          </td>
                          <td>
                            {item.membershipGroup?.name}
                          </td>
                          <td>
                            {item.lastDeckInteractionAt ? item.lastDeckInteractionAt.relativeTimeShort : ''}
                          </td>
                          <td>
                            {
                              !item.admin &&
                                <DeleteButton
                                  onConfirm={this.onDelete.bind(this, item.id)}
                                  message="Delete this user?"
                                  className="fa-block right"
                                />
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
          }
          <Paginator
            store={this.store}
          />
        </div>
      </Wrapper>
    );
  }
}

export default SortableColumns(UserIndex, UserStore, { sortColumn: 'last_active_at', sortIsAscending: false });
