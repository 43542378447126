import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import { AiServiceStore } from "@seedlang/stores";
import cx from 'classnames';
import InPlaceText from 'components/form/in_place_text';
const Wrapper = styled.div`

`;
@observer
class AiServiceIndex extends React.Component {
  componentDidMount() {
    AiServiceStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <table className = "table-wrapper admin-table">
          <thead>
            <tr>
              <th
                className = {cx('sortable', this.props.columnClassNames('client'))}
                onClick = {() => this.props.onSortColumn('client')}
              >
                Client
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('model'))}
                onClick = {() => this.props.onSortColumn('model')}
              >
                Model
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('flag_count'))}
                onClick = {() => this.props.onSortColumn('flag_count')}
              >
                # Flagged
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('translation_count'))}
                onClick = {() => this.props.onSortColumn('translation_count')}
              >
                # Translated
              </th>
            </tr>
          </thead>
          <tbody>
            {
              AiServiceStore.indexData.map((item) => {
                return (
                  <tr
                    key = {item.id}
                  >
                    <td>{item.client}</td>
                    <td>
                      <InPlaceText
                        allowUpdate
                        submitOnEnter
                        defaultValue = {item.model}
                        model = "ai_services"
                        field = "model"
                        id = {item.id}
                      />
                    </td>
                    <td>{item.flagCount}</td>
                    <td>{item.translationCount}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store = {AiServiceStore}
        />
      </Wrapper>
    );
  }
}

export default SortableColumns(AiServiceIndex, AiServiceStore, { sortColumn: 'name', sortIsAscending: true });