import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentenceEnterInput from 'components/exercise/exercise_sentence_enter_input';
import styled from '@emotion/styled';
import ExerciseEntry from 'components/exercise/exercise_entry';
import Prompt from 'components/exercise/prompt';
import { observable } from "mobx";
import { isBlank, isPresent } from '@seedlang/utils';
const Wrapper = styled.div`

`;

@observer
class ExerciseSentenceEnter extends React.Component {
  @observable focusedSentenceId;

  @autobind selectFirstSentence() {
    if (isPresent(ExerciseUI.exercise) && ExerciseUI.exercise.exerciseEntries.length > 0 && isPresent(ExerciseUI.exercise.exerciseEntries[0].sentence)) {
      this.focusExerciseEntry(ExerciseUI.exercise.exerciseEntries[0]);
    }
  }

  componentDidMount() {
    if (isBlank(this.focusedSentenceId)) {
      this.selectFirstSentence();
    }
  }

  componentDidUpdate() {
    if (isBlank(this.focusedSentenceId)) {
      this.selectFirstSentence();
    }
  }

  @autobind focusExerciseEntry(exerciseEntry) {
    if (exerciseEntry.sentence) {
      this.focusedSentenceId = exerciseEntry.sentence.id;
      const inputElement = document.getElementById(exerciseEntry.id);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }

  @autobind focusNextSentence(exerciseEntry) {
    const currentIndex = ExerciseUI.exercise.exerciseEntries.findIndex(item => item.id === exerciseEntry.id);
    if (ExerciseUI.exercise.exerciseEntries.length > currentIndex + 1) {
      const nextEntry = ExerciseUI.exercise.exerciseEntries[currentIndex + 1];
      if (nextEntry) {
        this.focusExerciseEntry(nextEntry);
      }
    }
  }

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && ExerciseUI.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                warningMessages={item.warningMessages}
                {...this.props}
              >
                {
                  item.prompt &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  item.sentence &&
                    <ExerciseSentenceEnterInput
                      revealOnEnter
                      exerciseEntry={item}
                      onFocus={() => this.focusedSentenceId = item.sentence.id}
                      isFocused={this.focusedSentenceId === item.sentence.id}
                      margin="0 20px 10px 0"
                      hideLanguageButton={!item.showSource}
                      afterReveal={() =>this.focusNextSentence(item)}
                    />
                }
              </ExerciseEntry>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseSentenceEnter);
