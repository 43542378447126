import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { VocabWordWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI, DeckUI, VocabUI } from '@seedlang/state';
import { flexCenter } from '@seedlang/style_mixins';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import Image from 'components/image';
import { ChatOutlineIcon, TableIcon, LearnedIcon } from '@seedlang/icons';
import Tabs from 'components/tabs';
import VocabSentences from 'components/vocab/vocab_sentences';
import TranslationComments from 'components/comment/translation_comments';
import ConjugationTable from 'components/conjugation_table';
import autobind from 'autobind-decorator';
import ReviewToggle from 'components/review/review_toggle';
import Spinner from 'components/spinner';
import { Link } from 'react-router';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import Modal from 'components/modal';
import SentenceSubmissionIndex from 'components/sentence_submission/sentence_submission_index';
import VocabLabels from "components/vocab/vocab_labels";
import VocabWordInfoList from "components/vocab/vocab_word_info_list";
import VocabWordConcepts from "components/vocab/vocab_word_concepts";
import LearnedButton from "components/review/learned_button";

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: 100%;
  position: relative;
  font-size: 16px;
  flex: 1;
  .word {
    margin: 0 1px 0 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    cursor: default;
    border: 1px solid transparent;
  }
  .word.highlight {
    background: #feffdc;
    border-radius: 5px;
  }
  .vocab-underline {
    display: block;
    margin-bottom: 10px;
  }
  .table-icon {
    height: 16px;
    svg {
      width: 14px;
    }
  }
  .chat-outline-icon {
    height: 16px;
    svg {
      width: 16px;
    }
  }
`;

const Section = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px 10px;
  position: relative;
`;

const CenterRow = styled.div`
  margin: ${(props) => props.margin};
  ${flexCenter()}
`;

const LinkBack = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  i {
    margin: 0 5px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  ${flexCenter()}
  gap: 10px;
  flex-direction: column;
`;

const ToggleButton = styled.div`
  ${flexCenter()}
  width: 100%;
  background: #ECECEC;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  i {
    margin-right: 4px;
  }
  &:hover {
    background: #ECECEC;
  }
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-decoration: underline;
  background: #ECECEC;
  text-align: center;
  padding: 5px;
`;

const TabsWrapper = styled.div`
  background: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const EmptyVideo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    width: 180px;
    height: 180px;
    border-radius: 180px;
    background: #CCC;
    margin-bottom: 10px;
    text-align: center;
    padding: 20px;
    border-radius: 2px solid #a5a5a5;
`;

const EmptyVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const LearnedWrapper = styled.div`
  margin-left: 10px;
`;

const TAB_ICONS = {
  sentences: <i className='fa fa-list-ul' />,
  conjugations: <TableIcon />,
  comments: <ChatOutlineIcon />,
}

@observer
class VocabWord extends React.Component {
  @observable showTriviaQuestionModal = false;

  componentDidMount() {
    document.getElementById('top').scrollIntoView();
  }

  @computed get fontSize() {
    return AppUI.layout.isMobile ? '13px' : '16px';
  }

  @computed get lineHeight() {
    return AppUI.layout.isMobile ? '17px' : '22px';
  }

  @computed get targetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.targetTextWithGender;
    } else {
      return this.props.word.targetTextWithDefiniteArticle
    }
  }

  @computed get pluralTargetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.pluralNounsStringWithoutDefiniteArticle;
    } else {
      return this.props.word.pluralNounsString;
    }
  }

  @computed get genderVariantTargetText() {
    if (AppUI.site.hideDefiniteArticle) {
      return this.props.word.genderVariant.targetTextWithGender;
    } else {
      return this.props.word.genderVariant.targetTextWithDefiniteArticle
    }
  }

  @autobind playSentenceVideo(sentence) {
    document.getElementById('top').scrollIntoView();
    this.props.playSentenceVideo(sentence);
  }

  render() {
    return (
      <Wrapper
        margin={this.props.params.worksheetId ? "10px 0 0 0" : null}
      >
        {
          this.showTriviaQuestionModal &&
            <Modal
              onCloseModal={() => this.showTriviaQuestionModal = false}
            >
              <SentenceCreate
                showTriviaOptionInputs
                triviaWordId={this.props.word.id}
              />
            </Modal>
        }
        {
          this.props.params.worksheetId &&
            <LinkBack
              onClick={() => AppUI.routeStore.routeToNamed('worksheets.vocab.show', {groupId: this.props.params.groupId, worksheetId: this.props.params.worksheetId})}
            >
              <i className='fa fa-angle-left' />
              Back to Vocab List
            </LinkBack>
        }
        <Section>
          <CenterRow>
            {
              !this.props.hasVideoClip &&
                <EmptyVideoWrapper>
                  {
                    !this.props.showSpinner &&
                      <EmptyVideo>
                        📹 We will record a video for this word soon!
                      </EmptyVideo>
                  }
                </EmptyVideoWrapper>
            }
            {
              this.props.hasVideoClip &&
                <VideoPlayerWithSupplement
                  hasVideoClip
                  autoPlay={this.props.autoPlay}
                  width={AppUI.layout.isMobile ? 150 : 180}
                  videoClip={this.props.videoClip}
                  supplementVideoClip={this.props.supplementVideoClip}
                />
            }
            {
              this.props.supplementImageUrl &&
                <Image
                  width={Math.floor(DeckUI.layout.deckWidth / 2.8)}
                  src={this.props.supplementImageUrl}
                />
            }
          </CenterRow>
          {
            this.props.word && this.props.word.id &&
              <CenterRow
                margin="10px 0 0 0"
              >
                <ButtonWrapper>
                  {
                    AppUI.userIsLoaded &&
                      <ReviewToggle
                        interfaceSource="vocab-word"
                        buttonText="Add to Reviews"
                        buttonHeight="30px"
                        width="140px"
                        buttonBackground="#FFF"
                        margin="0"
                        word={this.props.word}
                      />
                  }
                  <LearnedButton
                    word={this.props.word}
                  />
                </ButtonWrapper>
              </CenterRow>
          }
          {
            AppUI.wordStore.hasShowData &&
              <VocabWordInfoList
                {...this.props}
              />
          }
          {
            AppUI.user.translator && !AppUI.user.blockedTranslationInput &&
            <ToggleButton
              onClick={this.props.onToggleSourceInput}
            >
              <i className='fa fa-plus' />
              Add Translation
            </ToggleButton>
          }
          {
            this.props.word.concepts?.length > 0 &&
              <VocabWordConcepts
                word={this.props.word}
                expandedId={this.props.expandedId}
                onClickRow={this.props.onClickRow}
              />
          }
          {
            this.props.word &&
              <VocabLabels word={this.props.word} />
          }
        </Section>
        {
          this.props.showSpinner &&
            <Spinner
              className="blue"
            />
        }
        {
          !this.props.showSpinner && this.props.word &&
            <TabsWrapper>
              <Tabs
                tabs={this.props.tabs}
                icons={TAB_ICONS}
                showIcons={!AppUI.layout.isMobile}
                tabPadding="10px"
                contentPadding="10px 0"
                fontSize={AppUI.layout.isMobile ? "11px" : "13px"}
              >
                <VocabSentences
                  showVocabTriad
                  word={this.props.word}
                  playSentenceVideo={this.playSentenceVideo}
                  wordId={this.props.params.wordId}
                  fontSize={this.fontSize}
                  lineHeight={this.lineHeight}
                  isMobile={AppUI.layout.isMobile}
                  pro={AppUI.user.isMember}
                  onToggleCreateReview={this.props.onToggleCreateReview}
                  hasReview={this.props.hasReview}
                  onClickSentence={this.props.onClickSentence}
                  popupSentenceId={this.props.location.query['sentence']}
                />
                <SentenceSubmissionIndex
                  expandSentenceSubmissionCreate
                  wordId={this.props.word.id}
                />
                <TranslationComments
                  expanded
                  word={this.props.word}
                  loadOnMount
                  wordId={this.props.word.id}
                  hideTitle
                  padding="0"
                />
                {
                  this.props.word.isVerb && this.props.hasVerbConjugations &&
                    <ConjugationTable
                      blockUnmount
                      expand
                      word={this.props.word}
                      conjugatedVerbStrings={AppUI.conjugatedVerbStringStore.indexData}
                      labelMargin="0"
                    />
                }
              </Tabs>
            </TabsWrapper>
        }
        {
          AppUI.user.admin && this.props.word && this.props.word.id &&
            <AdminLink>
              <Link
                to={{name: 'builder.words.edit', params: {wordId: this.props.word.id}}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default VocabWordWrapper(VocabWord);
