import React from 'react';
import { observer } from 'mobx-react';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import { UserGroupStore, GroupStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import Copy from 'components/copy';
import { Link } from 'react-router';
import UserSearch from 'components/user_search';

@observer
class GroupEdit extends React.Component {

  constructor(props) {
    super(props);
    this.loadGroup();
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    GroupStore.setShowData(resp);
  }

  @autobind onAddUser(user) {
    UserGroupStore.update({data: {admin: true}, ids: {groupId: this.props.params.groupId, userId: user.id}}, this.afterChange)
  }

  @autobind afterChange() {
    GroupStore.reloadShow();
  }

  @autobind onDelete(user) {
    UserGroupStore.update({data: {admin: false}, ids: {userId: user.id, groupId: this.props.params.groupId}}, this.afterChange);
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={GroupStore.showData.name}
                model="groups"
                field="name"
                id={GroupStore.showData.id}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Type</legend>
              <InPlaceSelect
                includeBlank
                options={[['content-creator', 'Content Creator'], ['classroom', 'Classroom']]}
                value={GroupStore.showData.groupType}
                model="groups"
                field="group_type"
                id={GroupStore.showData.id}
                afterChange={this.loadGroup}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Invite Link</legend>
              <Copy
                text={`https://seedlang.com?assign_group_id=${this.props.params.groupId}`}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Admins</legend>
              <UserSearch
                onSubmit={this.onAddUser}
                placeholder='Search by Email'
              />
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    GroupStore.hasShowData && GroupStore.showData.userGroups.filter(item => item.admin).map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>
                            <Link
                              to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                            >
                              {item.user && item.user.name}
                            </Link>
                          </td>
                          <td>
                            <DeleteButton
                              width="50px"
                              onConfirm={() => this.onDelete(item.id)}
                              message="Remove this association?"
                            >
                              <i className='fa fa-times fa-block' />
                            </DeleteButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupEdit;
