import React from 'react';
import { observer } from 'mobx-react';
import { ReviewCreateWrapper } from '@seedlang/hoc';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import Button from 'components/button/button';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import WordSearch from 'components/word_search';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from '@seedlang/utils';
import VocabSentences from 'components/vocab/vocab_sentences';
import { WordSearchStore } from '@seedlang/stores';
import RadioButton from 'components/radio_button';
import SourceList from "components/source/source_list";
import Toggle from 'react-toggle';
import Spinner from 'components/spinner';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`
  border-radius: 5px;
  width: 100%;
  background: white;
`;

const Row = styled.div`
  display: flex;
  margin: 10px;
`;

const FormGroup = styled.div`
  margin: ${(props) => props.margin};
`;

const Form = styled.div`
`;

const Content = styled.div`
  background: #FFF;
  padding: 10px;
`;

const WordWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const WordSearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Expand = styled.div`
`;

const SentencesWrapper = styled.div`
  margin: 20px 0 0 0;
`;

const Note = styled.div`
  margin: 5px 0 20px 0;
  font-size: 14px;
  color: #333;
  font-style: italic;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .react-toggle--checked .react-toggle-track{
    background: ${props => props.highlightColor};
  }
`;

@observer
class ReviewCreate extends React.Component {

  render() {
    return (
      <Wrapper
        margin={AppUI.layout.isMobile ? "20px" : "0"}
      >
        {
          this.props.reviewCreatedMessage &&
            <div className='alert success'>
              {this.props.reviewCreatedMessage}
            </div>
        }
        <Row>
          <Option
            selected={this.props.showAddWord}
            onClick={() => this.props.onSetShowAddWord(true)}
          >
            <RadioButton
              margin="0 5px 0 0"
              value={this.props.showAddWord}
            />
            From Dictionary
          </Option>
          <Option
            selected={!this.props.showAddWord}
            onClick={() => this.props.onSetShowAddWord(false)}
          >
            <RadioButton
              margin="0 5px 0 0"
              value={!this.props.showAddWord}
            />
            Custom Review
          </Option>
        </Row>
        <Content>
          {
             this.props.showAddGermanWordTab &&
              <Form>
                <FormGroup>
                  {
                    !this.props.word &&
                      <WordSearchWrapper>
                        <Expand>
                          <WordSearch
                            keepInputOnBlur
                            placeholder="Search for a word"
                            filters={{root: true, video_clip_id: true, language_id: AppUI.targetLanguageId || 'DE'}}
                            queryStrings={{vocab_list: true}}
                            onSubmit={this.props.onSetWord}
                            onBlur={this.props.onBlurWordSearch}
                            onFocus={this.props.onFocusWordSearch}
                            onCreateValue={this.props.onCreateWord}
                            onKeyUp={this.props.onSetWordSearchValue}
                            displayField="targetTextWithDefiniteArticleAndSource"
                          />
                        </Expand>
                        {
                          !AppUI.wordSearchStore.showLoadingIcon && isPresent(this.props.wordSearchValue) && !this.props.word && !WordSearchStore.hasIndexData &&
                            <Button
                              borderRadius="10px"
                              margin="0 0 0 5px"
                              small
                              background="#FFF"
                              color="#333"
                              border="2px solid #d8d8d8"
                              fontSize="14px"
                              onClick={this.props.onCreateWord}
                            >
                              Add Word
                            </Button>
                        }
                        {
                          AppUI.wordSearchStore.showLoadingIcon &&
                            <Spinner 
                              margin="0 0 0 5px"
                            />
                        }
                      </WordSearchWrapper>
                  }
                  {
                    this.props.word &&
                      <WordWrapper>
                        {this.props.word.targetTextWithDefiniteArticle}
                        <i
                          className='fa fa-times'
                          style={{cursor: 'pointer', marginLeft: 5, color: 'red', fontSize: 16}}
                          onClick={() => this.props.onSetWord(null)}
                        />
                      </WordWrapper>
                  }
                  {
                    this.props.word &&
                      <SourceList
                        showFlag
                        flagWrapperWidth="25px"
                        flagWrapperAlignLeft
                        borderBottom="1px solid none"
                        leftAlign
                        padding="10px"
                        sourcePadding="2px"
                        sourceMargin="0"
                        user={AppUI.user}
                        word={this.props.word}
                        fontSize={12}
                        lineHeight={20}
                      />
                  }

                </FormGroup>
                {
                  this.props.word &&
                    <FormGroup
                      margin="20px 0 0 0"
                    >
                      <Text
                        bold
                      >
                        Card Type
                      </Text>
                      {
                        this.props.cardTypeOptions.map(([id, label]) => (
                          <div>
                            <InPlaceCheckbox
                              key={id}
                              icon="check"
                              value={this.props.cardTypeIds.includes(id)}
                              onChange={() => this.props.onSetCardTypeId(id)}
                            >
                              {label}
                            </InPlaceCheckbox>
                          </div>
                        ))
                      }
                      <Text
                        bold
                        margin="20px 0 0 0"
                      >
                        Review Deck
                      </Text>
                      <InPlaceSelect
                        allowUpdate
                        options={this.props.reviewDeckOptions}
                        value={this.props.deckId}
                        onChange={(val) => this.props.onSetDeckId(val)}
                      />
                    </FormGroup>
                }
              </Form>
          }
          {
            !this.props.showAddGermanWordTab &&
              <Form>
                <FormGroup>
                  <Text
                    bold
                  >
                    {this.props.showAddWord ? 'Target Word' : 'Front of Card'}
                  </Text>
                  <textarea
                    value={this.props.frontText}
                    onChange={(e) => this.props.onSetFrontText(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Text
                    bold
                    margin="20px 0 0 0"
                  >
                    {this.props.showAddWord ? 'English Translation' : 'Back of Card'}
                  </Text>
                  <textarea
                    value={this.props.backText}
                    onChange={(e) => this.props.onSetBackText(e.target.value)}
                  />
                </FormGroup>
                <Text
                  bold
                  margin="20px 0 0 0"
                >
                  Review Deck
                </Text>
                <InPlaceSelect
                  allowUpdate
                  options={this.props.reviewDeckOptions}
                  value={this.props.deckId}
                  onChange={(val) => this.props.onSetDeckId(val)}
                />
              </Form>
          }
          <Button
            onClick={() => this.props.onSubmit(null)}
            disabled={this.props.disableSubmit}
            margin="20px 5px 0 0"
          >
            <i
              className='fa fa-check'
              style={{marginRight: 5, color: 'white'}}
            />
            Create Review
          </Button>
          <Button
            onClick={this.props.onCancel}
            disabled={this.props.disableCancel}
            background={Theme.red}
            margin="20px 0 0 0"
          >
            <i
              className='fa fa-times'
              style={{marginRight: 5, color: 'white'}}
            />
            Cancel
          </Button>
          {
            !this.props.showAddGermanWordTab &&
              <div>
                <ToggleWrapper
                  highlightColor={Theme.green}
                >
                  <Toggle
                    icons={false}
                    onChange={this.props.toggleConvertCardSubmissions}
                    checked={AppUI.user["convertCardSubmissions"]}
                  />
                  <Text
                    fontSize="12px"
                    bold
                    margin="0 0 0 10px"
                  >
                    Convert Custom Reviews
                  </Text>
                </ToggleWrapper>
                <Note>
                  When words from your custom cards are added to our dictionary and recorded, the cards will be converted to word translation cards.
                </Note>
              </div>
          }
          {
            this.props.word && this.props.word.id && this.props.showAddWord &&
              <SentencesWrapper>
                <Text
                  bold
                  margin="0 0 10px 0"
                >
                  Sentences
                </Text>
                <VocabSentences
                  wordId={this.props.word.id}
                  onClickSentence={this.props.onClickSentence}
                />
              </SentencesWrapper>
          }
        </Content>
      </Wrapper>
    );
  }
}

export default ReviewCreateWrapper(ReviewCreate);
