import React from 'react';
import { observer } from 'mobx-react';
import ExerciseInputTable from 'pages/creator/exercises/exercise_input_table';
import ExerciseWordAssociations from 'components/exercise/exercise_word_associations';
import { ExerciseStore } from '@seedlang/stores';

@observer
class ExerciseInputSelect extends React.Component {

  render() {
    return (
      <div className='exercise-input-select'>
        {
          ExerciseStore.hasShowData &&
            <ExerciseWordAssociations
              wordAssociations={ExerciseStore.showData.selectedUniqueWordAssociations}
            />
        }
        <ExerciseInputTable
          {...this.props}
        />
      </div>
    );
  }
}

export default ExerciseInputSelect;
