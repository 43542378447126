import React from 'react';
import { observer } from 'mobx-react';
import { SettingsBillingWrapper } from '@seedlang/hoc';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import OutlineButton from 'components/button/outline_button';
import SettingsBillingMigrationSection from './settings_billing_migration_section';
import Spinner from 'components/spinner';
import SettingsBillingScheduledSubscriptionsSection from './settings_billing_scheduled_subscriptions_section';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  table {
    width: 100%;
    th {
      text-align: left;
      background: #505050;
      color: white;
      font-size: 14px;
      padding: 5px;
    }
    td {
      font-size: 16px;
      padding: 5px;
      i {
        margin-top: 4px;
      }
      &:first-of-type {
        width: 85%;
      }
    }
  }
`;

const Plan = styled.div`
  font-weight: bold;
`;

const Date = styled.div`
  font-size: 14px;
`;

const Cancelled = styled.span`
  color: red;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const NotPro = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 899px) {
    flex-direction: column;
    gap: 5px;
  }
`;

@observer
class SettingsBilling extends React.Component {

  @autobind async onManagePaymentMethod() {
    window.location.href = await AppUI.stripeStore.getCustomerPortalUrl({ returnUrl: window.location.href });
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading="3"
          margin="0 0 20px 0"
        >
          {AppUI.siteIsDefault ? 'Manage Subscription' : 'Manage Membership'}
        </Text>
        {
          !AppUI.userIsLoaded ? (
            <div style={{width: 0, margin: "20px 0"}}><Spinner background={AppUI.siteIsDefault ? null : AppUI.site.accentColor} /></div>
          ) : (
            <>
              {
                ((AppUI.user.permanentMembership && AppUI.siteIsDefault && !AppUI.user.mobilePaymentWithoutReceipt)) &&
                  <Text
                    color={Theme.green}
                    bold
                  >
                    You have a free membership. Congrats!
                  </Text>
              }
              {
                AppUI.user.currentUserGroup && AppUI.user.currentUserGroup.permanent  &&
                <div>
                  You currently have a free <b>{AppUI.user.currentGroupMembershipType?.name}</b>{isPresent(AppUI.user.currentUserGroup.permanentUntil?.date) ? ' until ' + AppUI.user.currentUserGroup.permanentUntil?.formattedDateWithYear : ''}.
                </div>
              }
              {
                AppUI.user.temporaryFreeMembership && AppUI.siteIsDefault && !AppUI.user.mobilePaymentWithoutReceipt &&
                  <Text
                    color={Theme.green}
                    bold
                  >
                    Congrats, you have a free membership! It will expire on {AppUI.user.memberUntil.formattedDateWithYear}.
                  </Text>
              }
              {
                AppUI.siteIsDefault && AppUI.user.mobilePaymentWithoutReceipt &&
                  <Text
                    color={Theme.green}
                    bold
                  >
                    Congrats, you have a membership!
                  </Text>
              }
              {
                AppUI.user.isMember && this.props.activeSubscription && !this.props.activeSubscription.oneTimePayment && isPresent(this.props.activeSubscription.currentPeriodEnd?.formattedDateWithYear) &&
                  <Text
                    bold
                  >
                    Your {this.props.activeSubscription?.membership?.title} is active and will {this.props.activeSubscription.cancelAtPeriodEnd ? 'end' : 'renew'} on {this.props.activeSubscription.currentPeriodEnd?.formattedDateWithYear}.
                  </Text>
              }
              {
                AppUI.user.isMember && this.props.activeSubscription && this.props.activeSubscription.oneTimePayment &&
                 <Text
                   bold
                 >
                  You have a lifetime membership. You are awesome! 🎉🎉🎉
                 </Text>
              }
              {
                AppUI.user.isMember && this.props.activeSubscription && this.props.activeSubscription.pending && !this.props.activeSubscription.oneTimePayment &&
                 <Text
                   bold
                 >
                  ⚠️ Please note that PayPal takes 24 hours to send the initial payment. If you wish to cancel your subscription, please do so after the 24 hours has passed.
                 </Text>
              }
              {
                isPresent(this.props.userSubscriptions) &&
                  <TableWrapper>
                    <table className="table-wrapper credit-cards">
                      <thead>
                        <tr>
                          <th>Plan</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.props.userSubscriptions.map(item => {
                            return (
                              <tr
                                key={item.id}
                              >
                                <td>
                                  <Plan>
                                    {item.membership && item.membership.title.replace(/<[^>]*>/g, '')}
                                  </Plan>
                                  <Date>
                                    {item.currentPeriodStart.formattedDateWithYear} - {item.pending ? 'Pending' : item.currentPeriodEnd.formattedDateWithYear}
                                    {
                                      item.cancelAtPeriodEnd &&
                                        <Cancelled> (Cancelled)</Cancelled>
                                    }
                                  </Date>
                                  {
                                    item.pendng && isPresent(item.trialEnd) &&
                                      <span className='trial-end'>
                                        {`(Not billed until ${item.trialEnd.formattedDateWithYear})`}
                                      </span>
                                  }
                                  {
                                    !item.cancelAtPeriodEnd && item.pending &&
                                      <div style={{fontSize: 12}}>
                                        Pending Paypal transfer
                                      </div>
                                  }
                                  {
                                    !item.cancelAtPeriodEnd && item.pending &&
                                      <div style={{fontSize: 12}}>
                                        It can take 24 hours for Paypal to transfer the initial payment.
                                      </div>
                                  }
                                </td>
                                <td>
                                  <div>{item.amountFormatted}</div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </TableWrapper>
              }
              {
                !AppUI.user.isMember &&
                  <div>
                    <Text
                      bold
                    >
                      You don't have an active subscription.
                    </Text>
                  </div>
              }
              <ButtonRow>
                {
                  (!AppUI.isPaymentMigrationInProgress && !AppUI.isPaymentMigrationStartable) && !this.props.activeSubscription.oneTimePayment &&
                    <Link
                      to={{name: 'memberships.index'}}
                    >
                      <Button
                        margin="10px 0 0 0"
                        background={AppUI.site.accentColor || Theme.blue}
                      >
                        {AppUI.siteIsDefault ? "Update your Subscription" : "Update Your Membership Tier"}
                      </Button>
                    </Link>
                }
                {
                  this.props.userSubscriptions.some(sub => sub.paymentMethod === 'stripe') &&
                    <Button
                      margin="10px 0 0 0"
                      background={AppUI.site.accentColor || Theme.blue}
                      onClick={this.onManagePaymentMethod}
                    >
                      Manage Payment Method
                    </Button>
                }
                {
                  this.props.showCancelButton && !this.props.activeSubscriptionIsMobile &&
                    <div>
                      <Link
                        to={{name: 'settings.cancel'}}
                      >
                        <OutlineButton
                          margin="10px 0 0 0"
                          selectedBackground={Theme.red}
                        >
                          <i className='fa fa-times'
                            style={{marginRight: 4}}
                          />
                          Cancel Membership
                        </OutlineButton>
                      </Link>
                    </div>
                }
              </ButtonRow>
              {
                AppUI.paymentMigrationEnabled &&
                  <SettingsBillingMigrationSection hasActiveSubscription={this.props.activeSubscription?.membership} />
              }
              {
                !AppUI.siteIsDefault && (
                  <SettingsBillingScheduledSubscriptionsSection />
                )
              }
            </>
          )
        }
      </Wrapper>
    );
  }
}

export default SettingsBillingWrapper(SettingsBilling);
