import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ExerciseProgress from 'components/exercise/exercise_progress';
import { Theme } from '@seedlang/constants';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isBlank, isPresent, pixify } from '@seedlang/utils';
import cx from 'classnames';
import { LockIcon, PinIcon } from '@seedlang/icons';
import autobind from 'autobind-decorator';
import GridItemTextPreview from 'components/worksheet/grid_item_text_preview';
import Tags from 'components/worksheet/tags';

const Wrapper = styled.div`
  padding: 20px;
  background: white;
  border-radius: ${props => props.borderRadius};
  width: ${props => props.width};
  position: relative;
  height: fit-content;
`;

const Name = styled.div`
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const EmptyName = styled.div`
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(-134deg, rgb(216 216 216) 30%, rgb(239 239 239) 100%);
  height: 30px;
  opacity: 0.6;
`;

const LabelWrapper = styled.div`
  z-index: ${Theme.z["over-foreground"]};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  background: ${(props) => props.background};
  color: white;
  padding: 3px 7px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  width: ${(props) => props.width};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumbnail = styled.div`
  width: 100%;
  height: fit-content;
  min-height: ${props => props.minHeight};
  background: linear-gradient(-134deg, rgb(216 216 216) 30%, rgb(239 239 239) 100%);
  border-radius: 10px;
  img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
  }
  .grayscale {
    filter: gray;
    filter: grayscale(1);
    transition: filter 0.3s ease;
    &:hover {
      filter: none;
    }
  }
  .pin-icon {
    position: absolute;
    height: 14px;
    width: 14px;
    padding: 5px;
    opacity: 0.7;
    right: 33px;
    top: 22px;
    svg {
      width: 14px;
      fill: #FFF;
    }
  }
`;

const EmptyProgress = styled.div`
  width: ${props => props.width};
  height: 15px;
  background: #353535;
  border-radius: 5px;
`; 

const CornerIconWrapper = styled.div`
  z-index: ${Theme.z["foreground"]};
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: ${props => props.background};
  position: absolute;
  top: 5px;
  right: 5px;
  pointer-events: none;
  .icon-wrapper {
    svg {
      fill: #FFF;
      width: ${props => props.iconWidth};
      position: absolute;
      left: ${props => props.left};
      top: ${props => props.top};
    }
  }
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 5px 0;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: ${props => props.borderRadius};
  }
`;

const YoutubeIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #ed2021;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 28px;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClickableContainer = styled.div`

`;

@observer
class WorksheetGridItem extends React.Component {

  @computed get width() {
    if (AppUI.layout.isMobile) {
      return AppUI.layout.viewportWidth;
    } else if (this.props.width) {
      return this.props.width;
    }
    return 370;
  }

  @computed get thumbnailHeight() {
    return this.width * 0.5;
  }

  @computed get showFreeLabel() {
    return isPresent(this.props.worksheet) && this.props.worksheet?.published && this.props.worksheet.accessWithoutMembership && !ExerciseUI.user.isMember;
  }

  @computed get showTags() {
    return (AppUI.userIsSignedIn && AppUI.routeStore.routeName !== AppUI.site.signedInHomePageRoute) || (AppUI.userIsSignedOut && AppUI.routeStore.routeName !== AppUI.site.signedOutHomePageRoute)
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.width)}
        borderRadius={ExerciseUI.layout.isMobile ? '0' : '10px'}
      >
        <ClickableContainer onClick={this.props.worksheet?.routeToFirstTab}>
        {
          isPresent(this.props.worksheet) && !this.props.worksheet.published &&
            <LabelWrapper>
              <Label
                background="#b48181"
                width="85px"
              >
                UNPUBLISHED
              </Label>
            </LabelWrapper>
        }
        {
          this.props.worksheet?.published && this.props.worksheet?.isNew && !this.showFreeLabel &&
            <LabelWrapper>
              <Label
                background={AppUI.site.secondaryAccentColor || AppUI.site.accentColor}
                width="40px"
              >
                NEW
              </Label>
            </LabelWrapper>
        }
        {
          this.showFreeLabel &&
            <LabelWrapper>
              <Label
                background={AppUI.site.secondaryAccentColor || AppUI.site.accentColor}
                width="40px"
              >
                FREE
              </Label>
            </LabelWrapper>
        }
        {
          this.props.worksheet && !this.props.worksheet.userHasAccess &&
            <CornerIconWrapper
              background="#777777"
              iconWidth="11px"
              left="10px"
              top="7px"
            >
              <LockIcon />
            </CornerIconWrapper>
        }
        {
          !this.props.hidePinIcon && this.props.worksheet && this.props.worksheet.userHasAccess && this.props.worksheet.pinned &&
            <CornerIconWrapper
              background={AppUI.site.secondaryAccentColor || "#777777"}
              iconWidth="14px"
              left="8px"
              top="8px"
            >
              <PinIcon />
            </CornerIconWrapper>
        }
        {
          isPresent(this.props.worksheet?.previewText) && isBlank(this.props.worksheet?.imageUrl) &&
            <GridItemTextPreview
              width={pixify(this.width - 40)}
              height={pixify(this.thumbnailHeight)}
              worksheet={this.props.worksheet}
            />
        }
          {
            (isBlank(this.props.worksheet?.previewText) || isPresent(this.props.worksheet?.imageUrl)) &&
            <Thumbnail
              hasAccess={this.props.worksheet?.userHasAccess}
              width={pixify(this.width - 40)}
              minHeight={pixify(this.thumbnailHeight)}
            >
              {

                isPresent(this.props.worksheet?.imageUrl) &&
                <img
                  alt=''
                  src={this.props.worksheet?.imageUrl}
                  className={cx({grayscale: !this.props.worksheet?.userHasAccess})}
                />
              }
            </Thumbnail>
          }
        <NameRow>
          <IconWrapper
            borderRadius={isPresent(this.props.worksheet?.podcastEpisode?.podcast?.podcastImage) ? '5px' : '40px'}
          >
            {
              isPresent(this.props.worksheet?.podcastEpisode?.podcast?.podcastImage) &&
                <img
                  alt=''
                  src={this.props.worksheet?.podcastEpisode?.podcast?.podcastImage?.image.url}
                />
            }
            {
              isBlank(this.props.worksheet?.podcastEpisode) &&
                <YoutubeIconWrapper>
                  <img
                    alt=''
                    src="/images/youtube_icon_inverse.png"
                  />
                </YoutubeIconWrapper>
            }
          </IconWrapper>
          {
            isPresent(this.props.worksheet?.name) &&
              <Name>
                {this.props.worksheet?.nameWithoutEpisodeCode}
              </Name>
          }
          {
            isBlank(this.props.worksheet?.name) &&
            <EmptyName />
          }
        </NameRow>
        {
          this.props.worksheet?.exerciseEntriesCount > 0 &&
            <ExerciseProgress
              largeVersion
              width={pixify(this.width - 40)}
              height="15px"
              hideCounts
              current={this.props.worksheet?.correctAnswersCount}
              total={this.props.worksheet?.exerciseEntriesCount}
            />
        }
        {
          isBlank(this.props.worksheet) &&
            <EmptyProgress
              width={pixify(this.width - 40)}
            />
        }
        </ClickableContainer>
        {
          isPresent(this.props.worksheet) && this.showTags &&
            <Details>
              <Tags
                withEpisodeCode
                worksheet={this.props.worksheet}
                onUpdateFilter={this.props.onUpdateFilter}
                gridLayout={this.props.gridLayout}
              />
            </Details>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetGridItem);
