import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import styled from '@emotion/styled';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import Copy from 'components/copy';
import { GroupStore, UserGroupStore } from '@seedlang/stores';
import UserSearch from 'components/user_search';
import { TreeUI } from '@seedlang/state';
import Button from 'components/button/button';
import Alert from 'components/alert';

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
`;

const Count = styled.div`
  margin: 0 0 10px 0;
`;

@observer
class ClassroomSettings extends React.Component {
  @observable showUpdateMessage = false;

  constructor(props) {
    super(props);
    this.loadGroup();
  }

  @computed get treeNodeOptions() {
    if (TreeUI.storiesTreeNodes) {
      return TreeUI.storiesTreeNodes.map(item => [item.id, item.name]);
    }
    return null;
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
    UserGroupStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onAddAdmin(user) {

  }

  @autobind onAddStudent(user) {
    UserGroupStore.create({data: {groupId: this.props.params.groupId, userId: user.id}}, this.afterChange)
  }

  @autobind afterChange() {
    GroupStore.reloadShow();
    UserGroupStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onDeleteAdmin(id) {
    
  }

  @autobind onDeleteStudent(id) {
    UserGroupStore.destroy({ids: {userGroupId: id}}, this.afterChange);
  }

  @autobind updateDailyXpGoal() {
    GroupStore.update({data: {daily_xp_goal: GroupStore.showData.dailyXpGoal}, ids: {groupId: this.props.params.groupId}}, this.afterUpdateDailyXpGoal);
  }

  @autobind afterUpdateDailyXpGoal() {
    this.showUpdateMessage = true;
    setTimeout(() => this.showUpdateMessage = false, 4000);
  }

  render() {
    return (
      <div>
        {
          this.showUpdateMessage &&
            <Alert
              textAlign="center"
              margin="10px 0 0 0"
            >
              Your classroom has been updated
            </Alert>
        }
        <Title>
          {GroupStore.showData?.name}
        </Title>
        <Count>
          {GroupStore.showData?.userGroupsCount} Students
        </Count>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={GroupStore.showData.name}
                model="groups"
                field="name"
                id={GroupStore.showData.id}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Daily Xp Goal</legend>
              <InPlaceText
                defaultValue={GroupStore.showData.dailyXpGoal}
                model="groups"
                field="daily_xp_goal"
                id={GroupStore.showData.id}
              />
              <Button
                marginTop="10px"
                onClick={this.updateDailyXpGoal}
              >
                Update for Students
              </Button>
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Invite Link</legend>
              <Copy
                text={`https://seedlang.com?assign_group_id=${this.props.params.groupId}`}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Highlighted Node</legend>
              <InPlaceSelect
                includeBlank
                options={this.treeNodeOptions}
                model="groups"
                field="highlighted_node_id"
                id={GroupStore.showData.id}
                value={GroupStore.showData.highlightedNodeId}
                afterChange={this.loadGroup}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>No-Access Text</legend>
              <InPlaceText
                richText
                defaultValue={GroupStore.showData.noAccessText}
                model="groups"
                field="no_access_text"
                id={GroupStore.showData.id}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Students</legend>
              <UserSearch
                margin="0 0 10px 0"
                onSubmit={this.onAddStudent}
                placeholder='Search by Email'
              />
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    UserGroupStore.hasIndexData && UserGroupStore.indexData.map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>{item.user && item.user.name}</td>
                          <td>
                            <DeleteButton
                              width="50px"
                              onConfirm={() => this.onDeleteStudent(item.id)}
                              message="Remove this association?"
                            >
                              <i className='fa fa-times fa-block' />
                            </DeleteButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Admins</legend>
              <UserSearch
                margin="0 0 10px 0"
                onSubmit={this.onAddAdmin}
                placeholder='Search by Email'
              />
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    GroupStore.hasShowData && GroupStore.showData.userGroups.filter(item => item.admin).map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>{item.user && item.user.name}</td>
                          <td>
                            <DeleteButton
                              width="50px"
                              onConfirm={() => this.onDeleteAdmin(item.id)}
                              message="Remove this association?"
                            >
                              <i className='fa fa-times fa-block' />
                            </DeleteButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassroomSettings;
