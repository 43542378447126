import React from 'react';
import { observer } from 'mobx-react';
import InPlaceSelect from 'components/form/in_place_select';
import { computed, observable } from 'mobx';
import { Api } from '@seedlang/stores';
@observer
class LanguageAiSummaryEdit extends React.Component {
  @computed get options() {
    return this.props.options.split(",");
  }
  @observable order = this.options;
  updateOptions(index, value) {
    this.order[index] = value;
  }
  submit() {
    const data = { data: {} };
    data.data[this.props.field] = this.order;
    Api.put({
      url: `/api/language_ai_summaries/${this.props.id}`,
      data: data,
      successCallback: ((resp) => {
        if (this.props.afterChange) {
          this.props.afterChange(this.order.toString());
        }
      }),
    });
  }
  render() {
    return (
      <div>
        <h1>Order of AI Services for {this.props.language} {this.props.translationType}</h1>
        {this.order.map((item, index) => {
          return (
            <div key = {item.id+"-"+index}>
              <p>#{index + 1} </p>
              <InPlaceSelect
                options = {this.options}
                value = {item}
                onChange = {(val) => {
                  this.updateOptions(index, val);
                }}
                allowUpdate
              />
            </div>
          )
        })}
        <button
          className = 'gray-button'
          onClick = {this.submit.bind(this)}
        >
          Submit Changes
        </button>
      </div>
    );
  }
}

export default LanguageAiSummaryEdit;