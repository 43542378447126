import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import styled from '@emotion/styled';
import LearnLayout from 'pages/main/learn_layout';
import ReviewProgress from 'components/review/review_progress';
import { Link } from 'react-router';
import { flexCenter } from '@seedlang/style_mixins';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { ReviewHomeWrapper } from '@seedlang/hoc';
import ContentLink from 'components/review/content_link';
import ReviewDecks from 'components/review/review_decks';
import Tab from 'components/tab';
import ReviewIndex from 'components/review/review_index';
import ReviewCreate from 'components/review/review_create';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import StreakRepairBanner from "components/review/streak_repair_banner";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReviewDecksWrapper = styled.div`
  ${flexCenter()}
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #CCC;
`;

const Home = styled.div`

`;

const TabText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 5px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
`;

@observer
class ReviewHome extends React.Component {

  @autobind onClickBannerUrl() {
    window.open(AppUI.user.bannerNotice.url, '_blank')
    AppUI.bannerNoticeStore.incrementClickedCount({ids: {bannerNoticeId: AppUI.user.bannerNotice.id}}, noop)
  }

  @autobind onDismissBannerNotice() {
    AppUI.authUserStore.update({data: {dismissed_banner_notice: AppUI.user.bannerNotice.id}}, noop);
    AppUI.user.set('dismissedBannerNotice', AppUI.user.bannerNotice.id);
  }

  render() {
    return (
      <LearnLayout>
        <Wrapper
          isMobile={AppUI.layout.isMobile || AppUI.layout.minimumHeight}
        >
          <Content
            isMobile={AppUI.layout.isMobile || AppUI.layout.minimumHeight}
          >
            {
              AppUI.user.bannerNotice && AppUI.user.dismissedBannerNotice !== AppUI.user.bannerNotice.id &&
                <PageOnboarding
                  margin="0 0 10px 0"
                  hideCloseIcon
                >
                  {AppUI.user.bannerNotice.text}
                  <ButtonRow>
                    <Button
                      margin="0 5px 0 0"
                      onClick={this.onClickBannerUrl}
                    >
                      {AppUI.user.bannerNotice.buttonText}
                    </Button>
                    <Button
                      background={Theme.red}
                      margin="0 0 0 5px"
                      onClick={this.onDismissBannerNotice}
                    >
                      Dismiss
                    </Button>
                  </ButtonRow>
                </PageOnboarding>
            }
            {
              AppUI.layout.iPadSafari && !AppUI.wasUiElementSeen('ipad-safari') &&
                <PageOnboarding
                  uiElement="ipad-safari"
                  margin="0 0 5px 0"
                >
                  We recommend using the <b>Chrome browser on an iPad</b>. On Safari, videos will not auto-play, but can be activated by clicking on them.
                </PageOnboarding>
            }
            {
              AppUI.userIsLoaded && AppUI.user.paymentProblems &&
                <PageOnboarding
                  hideCloseIcon
                  className='tree-onboarding'
                  uiElement="payment-problems"
                  margin="0 0 5px 0"
                >
                  <span>We are having trouble processing your payment. </span>
                  <Link
                    className='underline'
                    to={{name: "memberships.index"}}
                  >
                    Update your membership
                  </Link>
                  <span> to regain your Pro status.</span>
                  <div>
                    <span> You can also contact us on our </span>
                    <Link
                      className='underline'
                      to={{name: 'contact'}}
                    >
                      Contact page
                    </Link>
                    <span> for assistance.</span>
                  </div>
                </PageOnboarding>
            }
            {
              (this.props.showFirstTimeStreakExtendBanner || this.props.streakWasExtended) &&
                <PageOnboarding
                  hideCloseIcon
                  className='tree-onboarding'
                  uiElement="streak-extend-banner"
                  margin="0 0 5px 0"
                  padding='0 50px'
                >
                  <div style={{height: '140px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Text bold>🎉 {`Congratulations, you have reached ${AppUI.user.streakExtendGoal}XP today!`}</Text>
                    {
                      this.props.showFirstTimeStreakExtendBanner && !this.props.streakWasExtended &&
                      <>
                        <Text>When you reach such a high daily XP, you can be rewarded by extending your streak by one
                          day in the past. Do you want to extend your streak?</Text>
                        <ButtonsRow>
                          <Button
                            onClick={this.props.extendStreak}
                            width='70px'
                            small
                            borderRadius='5px'
                            background={Theme.green}
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={this.props.disableStreakExtension}
                            width='70px'
                            small
                            borderRadius='5px'
                            background={Theme.red}
                          >
                            No
                          </Button>
                        </ButtonsRow>
                      </>
                    }
                    {
                      this.props.streakWasExtended &&
                      <>
                        <Text>Your streak has been extended by one day!</Text>
                        <Text>Check your calendar to see your new streak.</Text>
                      </>
                    }
                  </div>
                </PageOnboarding>
            }
            {
              this.props.showOnboarding && !AppUI.wasUiElementSeen('find-reviews2') && AppUI.user.reviewCardsCount === 0 &&
                <PageOnboarding
                  className='tree-onboarding'
                  uiElement="find-reviews2"
                  margin="0 0 10px 0"
                >
                  <Text
                    center
                    color="#333"
                    heading="2"
                    margin="10px 0 0 0"
                  >
                    Your Mission
                  </Text>
                  <Text
                    center
                    bold
                    color="#333"
                    margin="10px 0 20px 0"
                  >
                    Find words and sentences that are new to you and add them to your "My Reviews" Deck.
                  </Text>
                </PageOnboarding>
            }
            <StreakRepairBanner
              store={AppUI.authUserDailyXpCountStore}
              dailyXpGoal={AppUI.user?.dailyXpGoal}
              userId={AppUI.user?.id}
              todaysDate={AppUI.user?.todaysDate}
            />
            <TabWrapper>
              <Row>
                <Tab
                  selected={AppUI.routeStore.routeName === 'reviews.home' || AppUI.routeStore.routeName === 'home'}
                  onClick={() => AppUI.routeStore.routeToNamed("reviews.home")}
                >
                  <TabText
                    fill={AppUI.routeStore.routeName === 'reviews.home' || AppUI.routeStore.routeName === 'home' ? Theme.blue : '#A9A9A9'}
                  >
                    <i className='fa fa-bookmark' />
                    Reviews
                  </TabText>
                </Tab>
                <Tab
                  selected={AppUI.routeStore.routeName === 'reviews.index'}
                  onClick={() => AppUI.routeStore.routeToNamed("reviews.index", {page: 1})}
                >
                  <TabText
                    fill={AppUI.routeStore.routeName === 'reviews.index' ? Theme.blue : '#A9A9A9'}
                  >
                    <i className='fa fa-list' />
                    Manage
                  </TabText>
                </Tab>
                <Tab
                  selected={AppUI.routeStore.routeName === 'reviews.create'}
                  onClick={() => AppUI.routeStore.routeToNamed("reviews.create")}
                >
                  <TabText
                    fill={AppUI.routeStore.routeName === 'reviews.create' ? Theme.blue : '#A9A9A9'}
                  >
                    <i className='fa fa-plus' />
                    Create
                  </TabText>
                </Tab>
                <Tab
                  selected={AppUI.routeStore.routeName === 'reviews.archive'}
                  onClick={() => AppUI.routeStore.routeToNamed("reviews.archive")}
                >
                  <TabText
                    fill={AppUI.routeStore.routeName === 'reviews.archive' ? Theme.blue : '#A9A9A9'}
                  >
                    <i className='fa fa-arrow-down' />
                    Archive
                  </TabText>
                </Tab>
              </Row>
              {
                (AppUI.routeStore.routeName === 'reviews.home' || AppUI.routeStore.routeName === 'reviews.archive' || AppUI.routeStore.routeName === 'home') &&
                  <Home>
                    <ReviewDecksWrapper
                      showOnboarding={this.props.showOnboarding}
                    >
                      <ReviewDecks
                        archive={AppUI.routeStore.routeName === 'reviews.archive'}
                        disabled={this.props.showOnboarding}
                      />
                    </ReviewDecksWrapper>
                    {
                      AppUI.userIsLoaded && AppUI.contentLinks.map(item => {
                        return (
                          <ContentLink
                            key={item.title}
                            isMobile={AppUI.layout.isMobile || AppUI.layout.minimumHeight}
                            {...item}
                          />
                        )
                      })
                    }
                  </Home>
              }
              {
                AppUI.routeStore.routeName === 'reviews.index' &&
                  <ReviewIndex />
              }
              {
                AppUI.routeStore.routeName === 'reviews.create' &&
                  <ReviewCreate />
              }
            </TabWrapper>
          </Content>
        </Wrapper>
        <ReviewProgress />
      </LearnLayout>
    );
  }
}

export default ReviewHomeWrapper(ReviewHome);
