import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { LevelStore, WordStore, WordTypeStore, WorksheetWordStore } from '@seedlang/stores';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceFlatSelect from 'components/form/in_place_flat_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import DeleteButton from 'components/button/delete_button';
import cx from 'classnames';
import { isBlank, isPresent } from '@seedlang/utils';
import WordSourceEdit from 'pages/builder/sources/word_source_edit';
import autobind from 'autobind-decorator';
import PluralEdit from 'pages/builder/words/plural_edit';
import ReactTooltip from 'react-tooltip';
import { Constants } from '@seedlang/constants';
import InPlaceText from "components/form/in_place_text";
import Alert from "components/alert";
import CheckIfTrue from "components/check_if_true";
import InfoTooltip from "components/info_tooltip";
import WordTags from "components/worksheet/word_tags";
import { ExerciseUI } from "@seedlang/state";
import WordBreakdown from "components/word_breakdown";
import Copy from "components/copy";

const Hover = styled.div`
  font-size: 12px;
  border: 1px dotted transparent;
  &:hover {
    border: 1px dotted #CCC;
  }
`;

const TargetTextCell = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .fa-edit {
    color: gray;
    cursor: pointer;
  }
`;
@observer
class WorksheetWordIndexRow extends React.Component {

  static defaultProps = {
    registerOptions: Constants.REGISTER_OPTIONS,
  }

  @observable selectedLevel;
  @observable showEditWarning;
  @observable showAlternateTargetText;
  @observable showUnderlineEdit = false;

  @autobind onChangeWordType(wordTypeId) {
    WordStore.update({ids: {wordId: this.word?.id}, data: {word_type_id: wordTypeId}}, this.props.afterChange);
  }

  @computed get level() {
    return this.selectedLevel || this.word?.level;
  }

  setLevel(levelId) {
    const level = LevelStore.indexData.find(item => item.id === levelId);
    if (level) {
      WordStore.update({ids: {wordId: this.word?.id}, data: {level_id: level.id}}, this.props.afterChange);
      this.selectedLevel = level;
    }
  }

  @autobind removeIsPlural() {
    WordStore.update({ids: {wordId: this.word?.id}, data: {plural: false}}, this.props.afterChange);
  }

  @computed get word() {
    return this.props.worksheetWord?.word;
  }

  @autobind setRegister(word, val) {
    this.word.set('register', val);
  }

  @computed get levelOptions() {
    if (!LevelStore.hasIndexData) { return }
    const options = LevelStore.indexData.filter(level => isPresent(level.abbreviation) && level.abbreviation !== 'N').map(level => [level.id, level.abbreviation]);
    if (this.word.wordType?.name === 'Number') {
      return options.slice(0,2);
    } return options;
  }

  @autobind toggleUnderlineModal() {
    this.showUnderlineEdit = !this.showUnderlineEdit;
  }


  render() {
    if (this.props.worksheetWord && this.word) {
      return (
        <tr
          className={cx('word-index-row', {
            invalid: this.props.worksheetWord.invalid,
            highlighted: this.props.highlighted,
          })}
        >
          {
            !this.props.hidePositions &&
              <td>
                <InPlaceText
                  model="worksheet_word"
                  field="position"
                  defaultValue={this.props.worksheetWord.position}
                  id={this.props.worksheetWord.id}
                  type="number"
                  step="1"
                  afterChange={this.props.afterChange}
                  color={this.props.positionsAreChronological ? null : 'gray'}
                />
              </td>
          }
          <td>
            {
              this.props.worksheetWord.invalid &&
              <div
                data-for={`warning-${this.word.id}`}
                data-tip
              >
                <i className="fa fa-warning fa-row-warning" />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`warning-${this.word.id}`}
                  class="custom-tooltip"
                >
                  {
                    this.props.worksheetWord.invalidPlural &&
                      <div>Invalid plural</div>
                  }
                  {
                    this.props.worksheetWord.invalidTarget &&
                      <div>Invalid target. It shouldn't contain an article.</div>
                  }
                  {
                    this.word.invalidWordType &&
                    <div>Invalid word type</div>
                  }
                  {
                    this.word.invalidSource &&
                    <div>Invalid source</div>
                  }
                  {
                    this.word.invalidLevel &&
                    <div>Missing level</div>
                  }
                  {
                    this.word.invalidGender &&
                    <div>Invalid gender</div>
                  }
                </ReactTooltip>
              </div>
            }
            {
              isPresent(this.word?.dictionaryUrl) &&
              <>
                <span
                  data-for={`external-info-${this.props.worksheetWord.id}`}
                  data-tip
                >
                  <div className="icon-wrapper">
                    <a
                      href={this.word?.dictionaryUrl}
                      target="seedlang"
                      className="no-underline no-hover"
                    >
                      <div className="encircle-icon">
                        <i className="fa fa-external-link" />
                      </div>
                    </a>
                  </div>
                </span>
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`external-info-${this.props.worksheetWord.id}`}
                  class="custom-tooltip"
                >
                  External Info
                </ReactTooltip>
              </>
            }
            {
              ExerciseUI.user.developer &&
              <Copy
                color="#CCC"
                text={this.word?.id}
              />
            }
            {
              isPresent(this.props.worksheetWord.batchCreateInput) &&
              <>
                <InfoTooltip
                  color='gray'
                  clickToCopy={this.props.worksheetWord.batchCreateInput}
                >
                  <div>Added by entering:</div>
                  <code>{this.props.worksheetWord.batchCreateInput}</code>
                </InfoTooltip>
              </>
            }
          </td>
          <td>
            <div className="text">
              {
                isPresent(this.word.wordType) && this.word.wordType?.id &&
                  this.word.wordType.abbreviation
              }
              {
                !isPresent(this.word.wordType) &&
                  <InPlaceSelect
                    includeBlank
                    style={{ width: 40 }}
                    options={WordTypeStore.indexData.filter(item => item.wordOption && item.languagesString?.match(this.word.languageId) && item.root).map(item => [item.id, item.name])}
                    onChange={this.onChangeWordType}
                  />
              }
            </div>
          </td>
          <td>
            <TargetTextCell>
              {
                isBlank(this.props.worksheetWord.wordStructure) && this.word.createdAt?.daysSince > 1 &&
                  <div
                    style={{cursor: 'not-allowed'}}
                    data-tip
                    data-for={`target-${this.props.worksheetWord.id}`}
                  >
                    { !ExerciseUI.site.showWordStress && this.word.targetText }
                    {
                      ExerciseUI.site.showWordStress &&
                        <WordBreakdown
                          word={this.word}
                          editable
                          afterChange={this.props.afterChange}
                        />
                    }
                    {
                      !ExerciseUI.site.showWordStress &&
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="solid"
                          id={`target-${this.props.worksheetWord.id}`}
                          class="custom-tooltip"
                        >
                          <div>If you want to edit this word,</div>
                          <div>please delete it and add it again with the correct spelling.</div>
                        </ReactTooltip>
                    }
                  </div>
              }
              {
                isBlank(this.props.worksheetWord.wordStructure) && this.word.createdAt?.daysSince <= 1 &&
                  <div>
                    {
                      !ExerciseUI.site.showWordStress &&
                      <InPlaceText
                        id={this.word.target.id}
                        model="targets"
                        field="text"
                        defaultValue={this.word.targetText}
                        onFocus={() => this.showEditWarning = true}
                        onBlur={() => this.showEditWarning = false}
                        afterChange={this.props.afterChange}
                      />
                    }
                    {
                       ExerciseUI.site.showWordStress &&
                        <WordBreakdown
                          word={this.word}
                          editable
                          afterChange={this.props.afterChange}
                        />
                    }
                    {
                      this.showEditWarning &&
                      <Alert
                        style={{position: 'absolute', width: '600px', zIndex: '2', marginTop: '5px', borderRadius: '10px'}}
                      >
                        <div>Only edit the word to <b>correct typos</b>, as this will modify every occurrence of this word.</div>
                        <div>If you want to replace it in the list with another word, delete this word and create another.</div>
                      </Alert>
                    }
                  </div>
              }
              {
                isPresent(this.props.worksheetWord.wordStructure) &&
                  <InPlaceText
                    id={this.props.worksheetWord.wordStructure.id}
                    model="word_structures"
                    field="text"
                    defaultValue={this.props.worksheetWord.wordStructure.text}
                    placeholder="Verb structure"
                    suffix={`(${this.word.targetText})`}
                  />
              }
              <div>
                {
                  this.word.isNoun && ['FR','CA','IT'].indexOf(this.word.languageId) !== -1 &&
                  <span data-tip data-for={`apostrophe-${this.props.worksheetWord.id}`} style={{marginRight: 4}}>
                    <InPlaceCheckbox
                      model="words"
                      field="article_has_apostrophe"
                      value={this.word.articleHasApostrophe}
                      id={this.word.id}
                      icon="check"
                    />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id={`apostrophe-${this.props.worksheetWord.id}`}
                      class="custom-tooltip"
                    >
                      takes an apostrophe
                    </ReactTooltip>
                  </span>
                }
                {
                  this.word.isVerb &&
                  <span
                    data-tip
                    data-for={`word-structure-${this.props.worksheetWord.id}`}
                    onClick={this.props.toggleVerbStructureModal.bind(this, this.props.worksheetWord)}
                  >
                    <i className='fa fa-edit' />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id={`word-structure-${this.props.worksheetWord.id}`}
                      class="custom-tooltip"
                    >
                      Display as a verb structure
                    </ReactTooltip>
                  </span>
                }
              </div>
            </TargetTextCell>
          </td>
          {
            this.props.showAlternateTargetText &&
              <td>
                <InPlaceText
                  submitOnEnter
                  id={this.word.id}
                  model="words"
                  field="alternate_target_text"
                  defaultValue={this.word.alternateTargetText}
                  fontSize='12px'
                />
              </td>
          }
          <td>
            {
              isPresent(this.props.worksheetWord.wordStructure) && !this.word.languageId.startsWith('EN') &&
                <InPlaceText
                  id={this.props.worksheetWord.wordStructure.id}
                  model="word_structures"
                  field="source_text"
                  defaultValue={this.props.worksheetWord.wordStructure.sourceText}
                  placeholder="Verb structure source"
                />
            }
            {
             isBlank(this.props.worksheetWord.wordStructure) && !this.word.languageId.startsWith('EN') &&
                <WordSourceEdit
                  word={this.word}
                  worksheetWord={this.props.worksheetWord}
                  onChange={this.props.afterChange}
                  languageId='EN'
                />
            }
            {
              this.word.languageId.startsWith('EN') &&
                <InPlaceText
                  id={this.word.id}
                  model="words"
                  field="definition"
                  defaultValue={this.word.definition}
                  placeholder="Definition"
                />
            }
          </td>
          <td>
            {
              this.word.plural &&
              <Hover
                onClick={this.removeIsPlural}
              >
                <i className='fa fa-check' /> Is Plural
              </Hover>
            }
            {
              ExerciseUI.site.showWordStress && isPresent(this.props.worksheetWord.word.pluralNouns) &&
                  <WordBreakdown
                    word={this.word.pluralNoun}
                    editable
                    afterChange={this.props.afterChange}
                  />
            }
            {
              !this.word.plural && this.word.wordType && this.word.wordType.name === 'Noun' && (!ExerciseUI.site.showWordStress || isBlank(this.props.worksheetWord.word.pluralNouns)) &&
                <PluralEdit
                  word={this.word}
                  onChange={this.props.afterChange}
                />
            }
          </td>
          {/*<td>*/}
          {/*  {*/}
          {/*    this.word.root && !this.word.plural && this.word.wordType?.name && (this.word?.wordType.name.startsWith('Adjective') || this.word?.isNoun || this.word?.isNumber) &&*/}
          {/*      <GenderVariantEdit*/}
          {/*        word={this.word}*/}
          {/*        placeholder=" "*/}
          {/*        displayField="targetWithGenderAndSentencesCount"*/}
          {/*        onChange={this.props.onChange}*/}
          {/*        border={this.word.isNoun ? 'none' : null}*/}
          {/*      />*/}
          {/*  }*/}
          {/*</td>*/}
          {
            isPresent(this.props.genderOptions) &&
              <td>
                {
                  this.word.wordType && this.word.wordType.name === 'Noun' &&
                  <span>
                    <div className="word-attribute select">
                      {
                        this.word.genderAbbreviation
                      }
                      {
                        !this.word.gender &&
                        <InPlaceFlatSelect
                          options={this.props.genderOptions}
                          model="words"
                          field="gender"
                          afterChange={this.props.afterChange}
                          value={this.word.gender}
                          id={this.word.id}
                        />
                      }
                    </div>
                  </span>
                }
              </td>
          }
          <td>
            <InPlaceSelect
              clickToEdit
              options={this.props.registerOptions}
              model="words"
              field="register"
              includeBlank
              id={this.word.id}
              value={this.word.register}
              afterChange={(val) => this.setRegister(this.word, val)}
            />
          </td>
          {
            !ExerciseUI.siteIsDefault && !ExerciseUI.site.language?.hasLessons &&
              <td>
                <WordTags
                  editable
                  word={this.word}
                  groupId={ExerciseUI.site.groupId}
                  onChange={this.props.afterChange}
                />
              </td>
          }
          <td>
            {
              LevelStore.hasIndexData &&
                <InPlaceFlatSelect
                  options={this.levelOptions}
                  model="words"
                  field="levelId"
                  onChange={(newValue) => this.setLevel(newValue)}
                  value={this.level ? this.level.id : null}
                  id={this.word.id}
                />
            }
          </td>
          {
            this.props.showHasVideo &&
            <td style={{textAlign: 'center'}}>
              <CheckIfTrue
                circle
                value={this.word.hasVideoClip}
                iconFontSize='18px'
                width='18px'
              />
            </td>
          }
          {
            !this.props.scopedToSentence &&
            <td>
              {
                this.props.onMoveToUnprocessedVocab &&
                <button
                  style={{border: 'none', padding: '5px 5px 0 5px'}}
                  data-tip
                  data-for={`unprocessed-${this.word.id}`}
                  onClick={() => this.props.onMoveToUnprocessedVocab(this.word.id)}
                >
                  <i className='fa fa-arrow-down' />
                  <ReactTooltip
                    place="left"
                    type="dark"
                    effect="solid"
                    id={`unprocessed-${this.word.id}`}
                    className="custom-tooltip"
                  >
                    Move to unprocessed vocab
                  </ReactTooltip>
                </button>
              }
              <DeleteButton
                onConfirm={() => this.props.onDelete(this.props.worksheetWord.id)}
                message={this.props.deleteMessage || "Delete this word?"}
                className="fa-block right"
              />
            </td>
          }
        </tr>
      );
    }
    return <div />
  }
}

export default ErrorBoundary(WorksheetWordIndexRow);
