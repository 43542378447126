import React from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '@seedlang/stores';
import TypeAhead from 'components/type_ahead';
import { isEmpty } from 'lodash';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

@observer
class UserSearch extends React.Component {

  static defaultProps = {
    searchByField: 'email',
    placeholder: 'Enter User Email',
  }

  componentDidMount() {
    UserStore.setSort(this.props.searchByField);
    UserStore.setQueryStrings({simple: true});
  }

  componentWillUnmount() {
    this.onClear();
  }

  @autobind onKeyUp(value) {
    value = value.trim();
    if (isEmpty(value)) {
      this.onClear();
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        UserStore.getIndex({queryStrings: {full_text_search: true, email: value}});
      }, 500);
    }
  }

  @autobind onSelect(obj) {
    this.props.onSubmit(obj);
  }

  @autobind onClear() {
    UserStore.clearIndexData();
    UserStore.clearFilter();
  }

  render() {
    return (
      <div className="user-search" style={{margin: this.props.margin}}>
        <TypeAhead
          onKeyUp={this.onKeyUp}
          onSelect={this.onSelect}
          onClear={this.onClear}
          options={UserStore.indexData}
          displayField='name'
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default UserSearch;
