import styled from '@emotion/styled';
import { computed } from 'mobx';
import { AppUI } from "@seedlang/state";
import { TagCategoryStore } from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Modal from 'components/modal';
import { observer } from 'mobx-react';
import React from 'react';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Text from 'components/text';
import InfoTooltip from 'components/info_tooltip';
import { isFunction } from 'lodash';
import BackEndExplainer from '../back_end_explainer';

const Wrapper = styled.div`
  .col-xs-6 {
    height: 100%;
  }
`;

const CheckBoxRow = styled.div`
  display: flex;
  gap: 10px; 
  flex-wrap: wrap;
`;

const CheckBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Instructions = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

@observer
class TagCategorySettings extends React.Component {

  hasNavigationItem(routeName) {
    let navigationItem = AppUI.site.navigationItems.find(item => item.routeName === routeName);
    return navigationItem?.published;
  }

  @computed get siteHasPodcastPage() {
    return this.hasNavigationItem('podcasts.index');
  }

  @computed get siteHasVideoPage() {
    return this.hasNavigationItem('videos.index');
  }

  componentWillUnmount() {
    if (isFunction(this.props.onUnmount)) {
      this.props.onUnmount();
    }
  }

  render() {
    return (
      <Wrapper>
        <Modal
            title={`\"${this.props.tagCategory?.name}\" Tag Category Settings`}
            background={AppUI.site.accentColor}
            gradient={isBlank(AppUI.site.accentColor)}
            onCloseModal={this.props.onCloseModal}
        >
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>
                  Tag Type
                  {
                    this.props.tagCategory.isAutomatic && 
                      <InfoTooltip position='right' icon='magic'>Settings disabled for automatic tags.</InfoTooltip>
                  } 
                </legend>
                <Instructions>This tag category is used for:</Instructions>
                <CheckBoxRow>
                  <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.isWorksheetTag}
                    model="tag_categories"
                    field="is_worksheet_tag"
                    icon="check"
                    disabled={this.props.tagCategory.isAutomatic}
                  >
                    Posts
                  </InPlaceCheckbox>
                  <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.isExerciseTag}
                    model="tag_categories"
                    field="is_exercise_tag"
                    icon="check"
                    disabled={this.props.tagCategory.isAutomatic}
                  >
                    Exercises
                  </InPlaceCheckbox>
                </CheckBoxRow>
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>
                  Config                 
                </legend>
                <CheckBoxColumn>
                  <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.selectOnlyOne}
                    model="tag_categories"
                    field="select_only_one"
                    icon="check"
                    disabled={this.props.tagCategory.isAutomatic}
                  >
                    Only Allow One Selection in Category
                    {
                      this.props.tagCategory.isAutomatic && 
                        <InfoTooltip position='left' icon='magic'>Setting disabled for automatic tags.</InfoTooltip>
                    }
                  </InPlaceCheckbox>
                </CheckBoxColumn>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Display on Post (Grid View)</legend>
                  {
                    this.props.tagCategory.slug === 'progress' &&
                    <Instructions>The Progress tag category is used for filtering. It's not displayed as a tag but as a progress bar when there are exercises.</Instructions>
                  }
                  <CheckBoxColumn>
                    <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.displayOnVideoWorksheets}
                    model="tag_categories"
                    field="display_on_video_worksheets"
                    icon="check"
                    disabled={!this.siteHasVideoPage || this.props.tagCategory.slug === 'progress'}
                  >
                    On Videos Page
                  </InPlaceCheckbox>
                  <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.displayOnPodcastWorksheets}
                    model="tag_categories"
                    field="display_on_podcast_worksheets"
                    icon="check"
                    disabled={!this.siteHasPodcastPage || this.props.tagCategory.slug === 'progress'}
                  >
                    On Podcasts Page
                  </InPlaceCheckbox>
                  <BackEndExplainer compact>
                    Tags are not displayed on the home page.
                  </BackEndExplainer>
                </CheckBoxColumn>
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Display in Filters</legend>
                <CheckBoxColumn>
                  <InPlaceCheckbox
                    id={this.props.tagCategory.id}
                    value={this.props.tagCategory.displayOnHomeFilters}
                    model="tag_categories"
                    field="display_on_home_filters"
                    icon="check"
                  >
                    On Home Page
                  </InPlaceCheckbox>
                    <InPlaceCheckbox
                      id={this.props.tagCategory.id}
                      value={this.props.tagCategory.displayOnVideoFilters}
                      model="tag_categories"
                      field="display_on_video_filters"
                      icon="check"
                      disabled={!this.siteHasVideoPage}
                    >
                      On Videos Page
                    </InPlaceCheckbox>
                    <InPlaceCheckbox
                      id={this.props.tagCategory.id}
                      value={this.props.tagCategory.displayOnPodcastFilters}
                      model="tag_categories"
                      field="display_on_podcast_filters"
                      icon="check"
                      disabled={!this.siteHasPodcastPage}
                    >
                      On Podcasts Page
                    </InPlaceCheckbox>
                </CheckBoxColumn>
              </fieldset>
            </div>
          </div>
        </Modal>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagCategorySettings);
