import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import { FlagTranslationIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background: whitesmoke;
  margin: 10px 0;
`;

const Source = styled.div`
  flex: 1;
`;

const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const FlagRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background: #CCC;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const Text = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  text-align: left;
  flex: 1;
`;

@observer
class SourceItem extends React.Component {
  @observable expanded = false;
  @observable showMessage = false;

  @autobind afterSubmission() {
    this.expanded = false;
    this.showMessage = true;
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  render () {
    return (
      <Wrapper>
        <Row>
          <LanguageIcon
            languageId={this.props.word.source && this.props.word.source?.languageId}
            margin="0 10px 0 0"
          />
          <Source>
            {`${this.props.word.source && this.props.word.source.text}${this.props.word.source?.ukSpelling ? ` / ${this.props.word.source.ukSpelling}` : ''}${this.props.word.source.displayGender ? this.props.word.genderAbbreviation : ''}`}
          </Source>
          <FlagTranslationIcon 
            onClick={() => this.expanded = !this.expanded}
          />
        </Row>
        {
          this.showMessage &&
            <FlagRow>
              Thank you for flagging this translation!
            </FlagRow>
        }
        {
          this.expanded &&
            <FlagRow>
              <Text>
                Flag this translation as incorrect?
              </Text>
              <Button 
                background={Theme.green}
                onClick={() => this.props.flagTranslation(this.props.word, this.afterSubmission)}
                margin="0 5px 0 0"
                height="30px"
                borderRadius="10px"
              >
                Yes
              </Button>
              <Button
                background={Theme.red}
                onClick={() => this.expanded = false}
                height="30px"
                borderRadius="10px"
              >
                No
              </Button>
            </FlagRow>
        }
    </Wrapper>
    );
  }
}

export default SourceItem;
