import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import { LanguageIcon } from '@seedlang/icons';
import { Link } from "react-router";
import Filter from "components/backend_filter/filter";
import { LanguageAiSummaryStore } from "@seedlang/stores";
import { AiServiceStore } from "@seedlang/stores";
import { computed, observable } from 'mobx';
import Modal from 'components/modal';
import LanguageAiSummaryEdit from './language_ai_summary_edit';
const Wrapper = styled.div`

`;
@observer
class LanguageAiSummaryIndex extends React.Component {
  @observable showWordsEdit = false;
  @observable showSentencesEdit = false;
  @observable editItem;

  @computed get aiServiceModels() {
    return AiServiceStore.indexData.map((item) => item.model).join(",")
  }
  componentDidMount() {
    LanguageAiSummaryStore.getIndex();
    AiServiceStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <Filter
          store = {LanguageAiSummaryStore}
          defaultSort = "language_id"
          namespace = "language-ai-summary-index"
          filters = {
            [
              {
                type: 'text',
                name: 'language_id',
                label: 'Language ID',
                placeholder: 'Language ID',
                default: '',
              },
            ]
          }
        />
        {
          this.showWordsEdit && this.editItem != undefined &&
          <Modal
            showRedX
            onCloseModal = {() => this.showWordsEdit = false}
          >
            <LanguageAiSummaryEdit
              translationType = "Words"
              language = {this.editItem.languageId}
              options = {this.aiServiceModels}
              field = "priorityOrderWords"
              afterChange = {(order) => {
                this.editItem.priorityOrderWords = order;
                this.showWordsEdit = false;
              }}
              id = {this.editItem.id}
            />
          </Modal>
        }
        {
          this.showSentencesEdit && this.editItem != undefined &&
          <Modal
            onCloseModal = {() => this.showSentencesEdit = false}
          >
            <LanguageAiSummaryEdit
              translationType = "Sentences"
              language = {this.editItem.languageId}
              options = {this.aiServiceModels}
              field = "priorityOrderSentences"
              afterChange = {(order) => {
                this.editItem.priorityOrderSentences = order;
                this.showSentencesEdit = false;
              }}
              id = {this.editItem.id}
            />
          </Modal>
        }
        <table className = "table-wrapper admin-table">
          <thead>
            <tr>
              <th /><th />
              <th
                className = {cx('sortable', this.props.columnClassNames('language_id'))}
                onClick = {() => this.props.onSortColumn('language_id')}
              >
                Language
              </th>
              <th>
                Priority Order Words
              </th>
              <th>
                Priority Order Sentences
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('translation_count_words'))}
                onClick = {() => this.props.onSortColumn('translation_count_words')}
              >
                # Translations Words
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('translation_count_sentences'))}
                onClick = {() => this.props.onSortColumn('translation_count_sentences')}
              >
                # Translations Sentences
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('flag_count_words'))}
                onClick = {() => this.props.onSortColumn('flag_count_words')}
              >
                # Flagged Words
              </th>
              <th
                className = {cx('sortable', this.props.columnClassNames('flag_count_sentences'))}
                onClick = {() => this.props.onSortColumn('flag_count_sentences')}
              >
                # Flagged Sentences
              </th>
            </tr>
          </thead>
          <tbody>
            {
              LanguageAiSummaryStore.indexData.map((item) => {
                return (
                  <tr
                    key = {item.id}
                  >
                    <td>
                      <Link
                        to = {{ name: 'builder.languages.edit', params: { languageId: item.languageId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <LanguageIcon
                        languageId = {item.languageId}
                      />
                    </td>
                    <td>{item.languageId}</td>
                    <td>
                      <div
                        className = {cx("text", "editable")}
                        onClick = {() => { this.showWordsEdit = true; this.editItem = item }}
                      >
                        {item.priorityOrderWords}
                      </div>
                    </td>
                    <td>
                      <div
                        className = {cx("text", "editable")}
                        onClick = {() => { this.showSentencesEdit = true; this.editItem = item }}
                      >
                        {item.priorityOrderSentences}
                      </div>
                    </td>
                    <td>{item.translationCountWords}</td>
                    <td>{item.translationCountSentences}</td>
                    <td>{item.flagCountWords}</td>
                    <td>{item.flagCountSentences}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store = {LanguageAiSummaryStore}
        />
      </Wrapper>
    );
  }
}

export default SortableColumns(LanguageAiSummaryIndex, LanguageAiSummaryStore, { sortColumn: 'name', sortIsAscending: true });