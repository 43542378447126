import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ExerciseEntryStore, SentenceStore, WordAssociationStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import SentenceBreakdown from 'components/sentence_breakdown';
import InPlaceText from 'components/form/in_place_text';
import MediaOptionsIndex from 'pages/creator/media_options/media_option_index';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isBlank, isPresent } from '@seedlang/utils';
import Modal from 'components/modal';
import WordSearch from 'components/word_search';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import RadioButton from 'components/radio_button';
import { capitalize } from "lodash";
import MergeWordsEdit from "pages/builder/sentences/merge_words_edit";
import Alert from 'components/alert';
import { Theme } from '@seedlang/constants';
import MultipleChoiceInput from "pages/builder/multiple_choice_input";
import Text from 'components/text';
import SentenceSearch from "components/sentence_search";
import { Link } from "react-router";
import InfoTooltip from "components/info_tooltip";
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`
  .exercise-input-table {
    font-size: 14px;
    line-height: 18px;
    .exercise-type-name {
      width: 100%;
      background: #8e8e8e;
      color: white;
      text-align: center;
    }
    .modal {
      height: 500px;
    }
    .in-place-text.position {
      input {
        width: 50px;
      }
    }
    .source {
      display: flex;
      .in-place-text {
        flex: 1;
      }
      .fa-times {
        margin: 2px 5px;
      }
    }
    .target {
      .fa-edit, .text-resizer {
        display: inline-block;
      }
    }
    .exercise-entries {
      margin-top: 10px;
      .exercise-entry {
        margin-bottom: 10px;
        background: white;
        padding: 10px;
        position: relative;
        .delete-button {
          width: 40px;
        }
        .exercise-entry-row:not(:last-child) {
          border-bottom: 1px solid #CCC;
        }
        .exercise-entry-row {
          display: flex;
          align-items: center;
        }
        .exercise-entry-setting {
          display: flex;
          justify-content: space-between;
          max-width: 700px;
          margin-top: 5px;
        }
        .grammar-tip {
          margin-top: 2px;
          margin-bottom: 5px;
        }
        .entry {
          flex: 1;
          padding: 5px;
          display: flex;
          align-items: center;
          .fa-edit {
            margin-top: 3px;
            margin-right: 8px;
          }
          .fa {
            cursor: pointer;
          }
        }
        .exercise-entry-label {
          color: #333;
          width: 170px;
          border-right: 1px solid #CCC;
          font-size: 12px;
          text-transform: uppercase;
          padding: 5px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
`;

@observer
class ExerciseInputTable extends React.Component {
  @observable editTargetId;
  @observable showWordSearchModal = false;
  @observable editExerciseEntryId;
  @observable mergedSentence;
  @observable newDuplicatedExerciseEntryId;
  @observable grammarTipExerciseEntryId;
  @observable alternateTextSentence;
  @observable addedVideoToExerciseEntry;

  @computed get exerciseEntries() {
    return this.exercise && this.exercise.exerciseEntries;
  }

  @computed get exercise() {
    return this.props.exercise ? this.props.exercise : ExerciseUI.exercise;
  }

  @computed get defaultInstruction() {
    switch (this.exercise.exerciseType?.slug) {
      case 'matchMedia':
        return "Click on the image that matches best.";
      case 'reorder':
        return "Translate this Sentence";
     case 'multipleChoice':
       return "Click on the answer that matches the image."
      default:
        return null;
    }
  }

  @autobind onDelete(id) {
    ExerciseEntryStore.destroy({ids: {exerciseEntryId: id}}, this.props.afterUpdate);
  }

  @autobind setWordAssociation(wordAssociation) {
    wordAssociation.setSelected(!wordAssociation.selected);
    WordAssociationStore.update({ids: {wordAssociationId: wordAssociation.id}, data: {selected: wordAssociation.selected}});
  }

  @autobind onCreateMediaOption(exerciseEntryId) {
    ExerciseUI.mediaOptionStore.create({data: {exercise_entry_id: exerciseEntryId}}, this.props.afterUpdate);
  }

  @autobind onDestroyMediaOption(id) {
    ExerciseUI.mediaOptionStore.destroy({ids: {mediaOptionId: id}}, this.props.afterUpdate);
  }

  @autobind onAddWord(word) {
    ExerciseEntryStore.update({ids: {exerciseEntryId: this.editExerciseEntryId}, data: {word_id: word.id}}, this.props.afterUpdate);
  }

  @autobind onAddSentence(sentence) {
    ExerciseEntryStore.update({ids: {exerciseEntryId: this.editExerciseEntryId}, data: {sentence_id: sentence.id}}, this.props.afterUpdate);
  }

  @autobind onDeleteSource(id) {
    // this should be deleting the translation_source
    // SourceStore.destroy({ids: {sourceId: id}}, this.props.afterUpdate)
  }

  @autobind onClearVideo(exerciseEntry) {
    if (isPresent(exerciseEntry.word)) {
      exerciseEntry.set('word',null);
      ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {word_id: null}}, this.props.afterUpdate);
    } else if (this.exercise.exerciseType.hideTarget && isPresent(exerciseEntry.sentence)) {
      exerciseEntry.set('sentence',null);
      ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {sentence_id: null}}, this.props.afterUpdate);
    }
  }

  @autobind onChangeFirstWordIsLowercase(resp, exerciseEntry) {
    // const selectedPositions = exerciseEntry.sentence.selectedWordAssociations.map(item => item.position)
    ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {firstWordIsLowercase: resp}}, this.props.afterUpdate);
    SentenceStore.update({ids: {sentenceId: exerciseEntry.sentence.id}, data: {targetText: exerciseEntry.sentence.targetText, keepCasing: !resp, selectedWordAssociationPositions: exerciseEntry.sentence.selectedWordAssociationPositions}}, this.props.afterUpdate);
  }

  @autobind setShowSource(value, exerciseEntry) {
    ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {showSource: value}}, this.props.afterUpdate);
  }

  @autobind duplicateExerciseEntry(exerciseEntry) {
    ExerciseEntryStore.duplicate({ids: {exerciseEntryId: exerciseEntry.id}}, this.afterDuplicateExerciseEntry);
  }

  @autobind afterDuplicateExerciseEntry(resp) {
    this.props.afterUpdate(resp);
    this.newDuplicatedExerciseEntryId = resp.exerciseEntryId;
  }

  @autobind toggleGrammarTip(exerciseEntry) {
    if (this.grammarTipExerciseEntryId === exerciseEntry.id) {
      this.grammarTipExerciseEntryId = null;
    } else {
      this.grammarTipExerciseEntryId = exerciseEntry.id;
    }
  }

  @autobind openMergeWordsEdit(sentence) {
    SentenceStore.getShow({ ids: {sentenceId: sentence.id, expanded: true }}, this.afterSentenceGetShow);
  }

  @autobind afterSentenceGetShow(resp) {
    SentenceStore.setShowData(resp);
    this.mergedSentence = resp;
  }

  @autobind afterMergeWordsEdit() {
    this.mergedSentence = null;
    this.props.afterUpdate();
  }

  @autobind firstWordAssociationIsSelected(entry) {
    return entry?.sentence?.wordAssociations[0]?.selected;
  }

  @autobind setSelectAllCorrectOptions(exerciseEntry) {
    const value = !exerciseEntry.selectAllCorrectOptions;
    exerciseEntry.set('selectAllCorrectOptions', value);
    ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {select_all_correct_options: value}}, this.props.afterUpdate);
  }

  @autobind setKeepOptionsInOrder(exerciseEntry) {
    const value = !exerciseEntry.keepOptionsInOrder;
    exerciseEntry.set('keepOptionsInOrder', value);
    ExerciseEntryStore.update({ids: {exerciseEntryId: exerciseEntry.id}, data: {keep_options_in_order: value}}, this.props.afterUpdate);
  }

  @autobind addVideo(exerciseEntry) {
    if (isBlank(exerciseEntry.sentence)) { return }
    SentenceStore.update({data: {simple: false}, ids: {sentenceId: exerciseEntry.sentence.id}}, (resp) => this.afterAddVideo(resp, exerciseEntry));
  }

  @autobind afterAddVideo(resp, exerciseEntry) {
    this.addedVideoToExerciseEntry = exerciseEntry.id;
    exerciseEntry.sentence.set('simple', false);
  }

  render() {
    return (
      <Wrapper>
      <div className='exercise-input-table'>
        {
          isPresent(this.mergedSentence) &&
            <Modal
              onCloseModal={() => this.mergedSentence = null}
              width="400px"
              title="Merge Words"
              gradient
              top="200px"
              height='500px'
              contentShouldScroll
            >
              <MergeWordsEdit
                words={SentenceStore.showData.enrichedWords}
                sentence={this.mergedSentence}
                afterChange={this.afterMergeWordsEdit}
              />
            </Modal>
        }
        {
          isPresent(this.alternateTextSentence) &&
            <Modal
              onCloseModal={() => {
                this.alternateTextSentence = null;
                this.props.afterUpdate();
              }}
              width="400px"
              title="Alternate answers"
              gradient
              top="300px"
            >
              <Text
                fontSize="12px"
                margin="0 0 10px 0"
              >
                If there are several alternatives, separate them with commas.
              </Text>
              <ul>
                {
                  this.alternateTextSentence.selectedWordAssociations?.map(item => {
                    return (
                      <li
                        style={{display: 'flex', alignItems: 'center'}}
                        key={item.id}
                      >
                        <div style={{width: '50%', fontSize: '16px'}}>
                          {item.targetText}
                        </div>
                        <InPlaceText
                          show
                          defaultValue={item.alternateText}
                          model="word_associations"
                          field="alternate_text"
                          id={item.id}
                          type="text"
                        />
                      </li>
                    )
                  })
                }
              </ul>
              {
                this.exercise.exerciseType?.slug === 'reorder' &&
                  <Text
                    fontSize="12px"
                    margin="5px 0 10px 0"
                  >
                    Remember to add alternative answers in the <b>extras</b> if you want users to be able to submit them.
                  </Text>
              }
            </Modal>
        }
        {
          this.showWordSearchModal &&
            <Modal
              height="500px"
              onCloseModal={() => {
                this.editExerciseEntryId = null;
                this.showWordSearchModal = false;
              }}
            >
              <WordSearch
                displayField="targetText"
                filters={{root: true, language_id: this.props.languageId || 'DE'}}
                onSubmit={(word) => this.onAddWord(word)}
              />
              {
                this.exercise.exerciseType?.hideTarget &&
                <div style={{marginTop: '10px'}}>
                  <SentenceSearch
                    languageId={this.props.languageId || 'DE'}
                    onSubmit={(sentence) => this.onAddSentence(sentence)}
                  />
                </div>
              }
            </Modal>
        }
        <div className='exercise-entries'>
          {
            this.exercise && this.exercise.exerciseEntries && this.exercise.exerciseEntries.map((item) => {
              return (
                <div
                  className='exercise-entry'
                  key={item.id}
                >
                  <div className='exercise-type-name'>
                    {
                      this.exercise.exerciseType.name
                    }
                  </div>
                  {
                    this.newDuplicatedExerciseEntryId === item.id &&
                      <Alert
                        fontSize="12px"
                        padding="3px 10px"
                        showCloseButton
                        onClose={() => this.newDuplicatedExerciseEntryId = null}
                        background={Theme.green}
                      >
                        Duplicated exercise entry
                      </Alert>
                  }
                  {
                    !this.props.hidePosition &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Position</div>
                        <div className='entry'>
                          <InPlaceText
                            defaultValue={item.position}
                            model="exercise_entries"
                            field="position"
                            id={item.id}
                            type="number"
                            step="1"
                            afterChange={this.props.afterUpdate}
                          />
                        </div>
                        <div
                          data-for={`duplicate-${item.id}`}
                          className="delete-button-wrapper"
                          style={{margin: "7px 10px 0 0", cursor: "pointer"}}
                          onClick={() => this.duplicateExerciseEntry(item)}
                          data-tip
                        >
                          <i className="fa fa-solid fa-clone" />
                          <ReactTooltip
                            place="left"
                            type="dark"
                            effect="solid"
                            id={`duplicate-${item.id}`}
                            class="custom-tooltip"
                          >
                            <div>Duplicate this exercise</div>
                          </ReactTooltip>
                        </div>
                        <div className='delete-button'>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message="Delete this entry?"
                            className="fa-block right"
                          />
                        </div>
                      </div>
                  }
                  {
                    this.defaultInstruction && !this.props.hideInstruction &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Instruction</div>
                        <div className='entry'>
                          <InPlaceText
                            inputType="textarea"
                            id={item.id}
                            field="instruction"
                            defaultValue={item.instruction}
                            placeholder={this.defaultInstruction}
                            model="exercise_entry"
                          />
                        </div>
                      </div>
                  }
                  <div className='exercise-entry-row'>
                    <div className='exercise-entry-label'>Prompt</div>
                    <div className='entry'>
                      <InPlaceText
                        inputType="textarea"
                        id={item.id}
                        field="prompt"
                        defaultValue={item.prompt}
                        model="exercise_entry"
                      />
                    </div>
                  </div>
                  {
                    this.exercise.exerciseType && !this.exercise.exerciseType.hideTarget &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Target</div>
                        <div className='entry target'>
                          {
                            !this.props.hideBreakdown &&
                              <i
                                className='fa fa-edit'
                                onClick={() => this.editTargetId = this.editTargetId === item.sentence?.id ? null : item.sentence.id}
                              />
                          }
                          {
                            item.sentence && !item.sentence?.simple &&
                              <Link
                                to={{ name: 'builder.sentences.edit', params: { sentenceId: item.sentence.id } }}
                                className="on-click"
                                target='_blank'
                              >
                                <i style={{marginRight: '10px', fontSize: '12px'}} className="fa fa-chevron-right fa-block" />
                              </Link>
                          }
                          {
                            !this.props.hideBreakdown && item.sentence && this.editTargetId !== item.sentence.id &&
                              <SentenceBreakdown
                                highlightSelected
                                withAlternateText
                                showUnderline
                                multipleSelections
                                alertOnNoSelections
                                sentence={item.sentence}
                                onClick={this.setWordAssociation}
                                maintainCase={!this.exercise.capitalizeEntries}
                              />
                          }
                          {
                            (item.sentence && (this.props.hideBreakdown || (item.sentence && this.editTargetId === item.sentence.id))) &&
                              <InPlaceText
                                submitOnEnter
                                rejectBlank
                                inputType="textarea"
                                height="60px"
                                defaultValue={item.sentence.targetText}
                                model="sentences"
                                field="target_text"
                                data={{keep_casing: !item.firstWordIsLowercase, selected_word_association_target_texts: item.sentence.selectedWordAssociationTargetTexts}}
                                id={item.sentence.id}
                                afterChange={() => {
                                  this.editTargetId = null;
                                  this.props.afterUpdate();
                                }}
                              />
                          }
                          {
                            isBlank(item.sentence) &&
                            <div
                              data-tip
                              data-for={`${item.id}-warning`}
                            >
                              <i className='fa fa-warning' />
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                id={`${item.id}-warning`}
                                class="custom-tooltip"
                              >
                                <div>This exercise entry has no sentence.</div>
                                {
                                  this.props.exercise.exerciseType.slug === 'matchText' &&
                                  <div>The prompt will be shown as a clickable option at the top of the exercise.</div>
                                }
                                {
                                  this.props.exercise.exerciseType.slug !== 'matchText' &&
                                    <div>Add a sentence or delete the exercise entry.</div>
                                }
                              </ReactTooltip>
                            </div>
                          }
                          {
                            item.sentence?.selectedWordAssociations?.length > 0 &&
                              <span
                                data-for={`alternate-${item.id}`}
                                data-tip
                                style={{marginRight: 10, textTransform: 'initial'}}
                                onClick={() => this.alternateTextSentence = item.sentence}
                              >
                                <i className='fa fa-plus'/>
                                <ReactTooltip
                                  place="left"
                                  type="dark"
                                  effect="solid"
                                  id={`alternate-${item.id}`}
                                  className="custom-tooltip"
                                >
                                  <div>Add Alternate Answers</div>
                                </ReactTooltip>
                              </span>
                          }
                          <span
                            data-for={`merge-${item.id}`}
                            data-tip
                            style={{marginRight: 4, textTransform: 'initial'}}
                            onClick={() => this.openMergeWordsEdit(item.sentence)}
                          >
                            <i className='fa fa-angle-double-left'/>
                            <ReactTooltip
                              place="left"
                              type="dark"
                              effect="solid"
                              id={`merge-${item.id}`}
                              className="custom-tooltip"
                            >
                              <div>Merge words</div>
                            </ReactTooltip>
                          </span>
                        </div>
                      </div>
                  }
                  {
                    item.showSource &&
                      <div
                        className='exercise-entry-row'
                        style={{background: isBlank(item.sourceText) && !isPresent(item.sentence?.defaultSource) ? 'rgba(255,241,241,0.5)' : '#FFF'}}
                      >
                        <div className='exercise-entry-label'>
                          English Source
                          <span
                            data-for={`source-${item.id}`}
                            data-tip
                            style={{marginRight: 4, textTransform: 'initial'}}
                          >
                            <i className='fa fa-info-circle'/>
                            <ReactTooltip
                              place="right"
                              type="dark"
                              effect="solid"
                              id={`source-${item.id}`}
                              className="custom-tooltip"
                            >
                              <div>This source will be shown when the card is revealed.</div>
                            </ReactTooltip>
                          </span>
                        </div>
                        {
                          (item.sourceText || (!isPresent(item.sentence?.defaultSource) && this.exercise.exerciseType?.slug === 'reorder')) &&
                            <div className='entry target'>
                              <InPlaceText
                                inputType="textarea"
                                style={{height: 60}}
                                defaultValue={item.sourceText}
                                model="exercise_entries"
                                field="source_text"
                                id={item.id}
                                afterChange={this.props.afterUpdate}
                              />
                            </div>
                        }
                        {
                          item.sentence && isPresent(item.sentence.defaultSource) &&
                            <div className='entry'>
                              <div className='source'>
                                <InPlaceText
                                  inputType="textarea"
                                  style={{height: 60}}
                                  defaultValue={item.sentence.defaultSource.text}
                                  model="sources"
                                  field="text"
                                  id={item.sentence.defaultSource.id}
                                />
                              </div>
                            </div>
                        }
                        { isBlank(item.sourceText) && !isPresent(item.sentence?.defaultSource) &&
                            <div
                              data-for={`warning-source-${item.id}`}
                              data-tip
                            >
                              <i className="fa fa-warning fa-row-warning" />
                              <ReactTooltip
                                place="left"
                                type="dark"
                                effect="solid"
                                id={`warning-source-${item.id}`}
                                class="custom-tooltip"
                              >
                                <div>This card needs a source because you indicated that<br/>the prompt is not the English source of the target.</div>
                              </ReactTooltip>
                            </div>
                        }
                      </div>
                  }

                  {
                    this.props.allowTargetInput &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Video</div>
                        <div className='entry target'>
                          {
                            isPresent(item.word) &&
                              <div className='target-wrapper'>
                                <div className='target'>
                                  {item.word.targetTextWithDefiniteArticle}
                                  <i
                                    className='fa fa-times fa-on-click'
                                    onClick={() => this.onClearVideo(item)}
                                  />
                                </div>
                                <VideoPlayersWithType
                                  videoClip={item.word.videoClip}
                                />
                              </div>
                          }
                          {
                            isPresent(item.sentence) && !item.sentence.simple && (this.exercise.exerciseType?.hideTarget || item.sentence.hasTargetVideoClip) &&
                            <div className='target-wrapper'>
                              <div className='target'>
                                <Link target='_blank' to={{name: 'builder.sentences.edit', params: {sentenceId: item.sentence.id}}}>{item.sentence.targetText}</Link>

                                {
                                  this.exercise.exerciseType?.hideTarget &&
                                  <i
                                    className='fa fa-times fa-on-click'
                                    onClick={() => this.onClearVideo(item)}
                                  />
                                }
                                {
                                  !this.exercise.exerciseType?.hideTarget &&
                                  <InfoTooltip>This video is attached to the target sentence.</InfoTooltip>
                                }
                              </div>
                              <VideoPlayersWithType
                                videoClip={item.sentence.target?.videoClip}
                              />
                            </div>
                          }
                          {
                            !isPresent(item.word) && !item.sentence?.hasTargetVideoClip && (!this.exercise.exerciseType?.hideTarget || isBlank(item.sentence)) &&
                              <InfoTooltip
                                icon='plus'
                                onClick={() => {
                                  this.editExerciseEntryId = item.id;
                                  this.showWordSearchModal = true
                                }}
                              >
                                {this.exercise.exerciseType?.hideTarget ? 'Select a word or a sentence' : 'Select a word'}
                              </InfoTooltip>
                          }
                          {
                            this.props.inDeck && isBlank(item.word) && item.sentence?.simple &&
                            <span
                              data-for={`video-${item.id}`}
                              data-tip
                              style={{marginRight: 10, textTransform: 'initial', color: "gray"}}
                              onClick={() => this.addVideo(item)}
                            >
                              <i className='fa fa-film'/>
                              <ReactTooltip
                                place="right"
                                type="dark"
                                effect="solid"
                                id={`video-${item.id}`}
                                className="custom-tooltip"
                              >
                                <div>Add a Video to this Sentence</div>
                              </ReactTooltip>
                            </span>
                          }
                          {
                            this.props.inDeck && isBlank(item.word) && isPresent(item.sentence) && !item.sentence?.simple && !item.sentence?.hasTargetVideoClip &&
                            <span
                              data-for={`video-${item.id}`}
                              data-tip
                              style={{marginRight: 10, textTransform: 'initial'}}
                            >
                                <i className='fa fa-film'/>
                                <ReactTooltip
                                  place="right"
                                  type="dark"
                                  effect="solid"
                                  id={`video-${item.id}`}
                                  className="custom-tooltip"
                                >
                                  <div>You can record a video for this sentence in the Video section.</div>
                                </ReactTooltip>
                              </span>
                          }
                        </div>
                      </div>
                  }
                  {
                    this.props.showMediaOptions &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Media {this.exercise.exerciseType?.slug === 'multipleChoice' ? 'Prompt' : 'Options'}</div>
                        <div className='entry target'>
                          <MediaOptionsIndex
                            exerciseType={this.exercise.exerciseType}
                            exerciseEntry={item}
                            onCreateMediaOption={this.onCreateMediaOption}
                            onDestroyMediaOption={this.onDestroyMediaOption}
                            onChangeMedia={this.props.afterUpdate}

                          />
                        </div>
                      </div>
                  }
                  {
                    isPresent(item.multipleChoiceOptions) &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label' style={{height: '150px'}}>Multiple Choice options</div>
                          <div className='entry target'>
                            <MultipleChoiceInput
                              multipleChoiceOptions={item.multipleChoiceOptions}
                              background='white'
                              width='100%'
                              hideEnglish
                              inDeck={this.props.inDeck}
                            />
                          </div>
                      </div>
                  }
                  {
                    this.exercise.exerciseType.allowExtraWords &&
                      <div className='exercise-entry-row'>
                        <div className='exercise-entry-label'>Extras (w1,w2)</div>
                        <div className='entry target'>
                          <InPlaceText
                            blockOverwrite
                            defaultValue={item.extraWords}
                            model="exercise_entries"
                            field="extra_words"
                            id={item.id}
                            afterChange={this.props.afterUpdate}
                          />
                        </div>
                      </div>
                  }
                  {
                    (Constants.RTL_LANGUAGE_IDS.indexOf(AppUI.site.languageId) !== -1 || isBlank(AppUI.site.languageId)) && this.firstWordAssociationIsSelected(item) &&
                      <div className='exercise-entry-setting'>
                        <div>The first word of the sentence should appear as...</div>
                        <div style={{display: 'flex'}}>
                          <RadioButton
                            onClick={() => this.onChangeFirstWordIsLowercase(false, item)}
                            value={item.firstWordIsLowercase === false}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 20px"
                          />
                          <span>
                            {capitalize(item.sentence.wordAssociations[0].targetText)}
                          </span>
                          <RadioButton
                            onClick={() => this.onChangeFirstWordIsLowercase(true, item)}
                            value={item.firstWordIsLowercase}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 7px"
                          />
                          <span>
                            {item.sentence.wordAssociations[0].targetText.toLowerCase()}
                          </span>
                        </div>
                      </div>
                  }
                  {
                    this.exercise.exerciseType.slug === 'reorder' && !AppUI.site.languageId?.startsWith('EN') &&
                      <div className='exercise-entry-setting'>
                        <div>The prompt is the English translation of the target.</div>
                        <div style={{display: 'flex'}}>
                          <RadioButton
                            onClick={() => this.setShowSource(false, item)}
                            value={item.showSource === false}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 20px"
                          />
                          <span>
                            Yes
                          </span>
                          <RadioButton
                            onClick={() => this.setShowSource(true, item)}
                            value={item.showSource}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 7px"
                          />
                          <span>
                            No
                          </span>
                        </div>
                      </div>
                  }
                  {
                    ['multipleChoice', 'matchMedia'].indexOf(this.exercise.exerciseType.slug) !== -1 && item.numberOfCorrectOptions > 1 &&
                      <div className='exercise-entry-setting'>
                        <div>Users must select <b><i>all</i></b> correct answers</div>
                        <div style={{display: 'flex'}}>
                          <RadioButton
                            onClick={() => this.setSelectAllCorrectOptions(item)}
                            value={item.selectAllCorrectOptions}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 20px"
                          />
                          <span>
                            Yes
                          </span>
                          <RadioButton
                            onClick={() => this.setSelectAllCorrectOptions(item)}
                            value={!item.selectAllCorrectOptions}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 7px"
                          />
                          <span>
                            No
                          </span>
                        </div>
                      </div>
                  }
                  {
                    ['multipleChoice', 'matchMedia'].indexOf(this.exercise.exerciseType.slug) !== -1 &&
                      <div className='exercise-entry-setting'>
                        <div>Randomize options for user</div>
                        <div style={{display: 'flex'}}>
                          <RadioButton
                            onClick={() => this.setKeepOptionsInOrder(item)}
                            value={!item.keepOptionsInOrder}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 7px"
                          />
                          <span>
                            Yes
                          </span>
                          <RadioButton
                            onClick={() => this.setKeepOptionsInOrder(item)}
                            value={item.keepOptionsInOrder}
                            height="12px"
                            width="12px"
                            margin="3px 3px 3px 20px"
                          />
                          <span>
                            No
                          </span>
                        </div>
                      </div>
                  }
                  {
                    this.props.showGrammarTip &&
                      <div
                        className='grammar-tip'
                        style={isPresent(item.grammarTip) ?
                          {borderRadius: '10px',
                            border: '1px solid #80808080',
                            padding: '3px 10px',
                            marginTop: '5px',
                            background: 'rgba(211,211,211,0.29)'}
                          : null
                        }
                      >
                        <span
                          onClick={() => isBlank(item.grammarTip) ? this.toggleGrammarTip(item) : null}
                          style={{display: 'flex', alignItems: 'center', cursor: isBlank(item.grammarTip) ? 'pointer' : null}}
                        >
                          <span
                            style={{marginRight: "5px", color: isPresent(item.grammarTip) ? Theme.orange : null}}
                          >
                            <i className='fa fa-lightbulb-o' style={{fontSize: "16px"}}/>
                          </span>
                          { isPresent(item.grammarTip) ? 'Tip:' : 'Add a tip' }
                        </span>
                        {
                          (this.grammarTipExerciseEntryId === item.id || isPresent(item.grammarTip)) &&
                            <InPlaceText
                              show={isBlank(item.grammarTip)}
                              smallRichText
                              defaultValue={item.grammarTip}
                              inputType="textarea"
                              model="exercise_entries"
                              field="grammar_tip"
                              id={item.id}
                              afterChange={this.props.afterUpdate}
                            />
                        }
                      </div>
                  }
                  {
                    item.id === this.addedVideoToExerciseEntry &&
                    <Alert margin='10px 0 0 0'>
                      <div>You can now set up this sentence & record a video in the Video section.</div>
                      <Link target='_blank' to={{name: 'builder.sentences.edit', params: {sentenceId: item.sentence.id}}} onlyActiveOnIndex>Go to Sentence</Link>
                    </Alert>
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      </Wrapper>
    );
  }
}

export default ExerciseInputTable;
