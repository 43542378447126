import React from 'react';
import { observer } from 'mobx-react';
import { WordComprehendCardWrapper } from '@seedlang/hoc';
import MediaSection from 'components/vocab_card/media_section';
import CardSection from 'components/vocab_card/card_section';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import CardSourceList from 'components/vocab_card/card_source_list';
import { DeckUI, AppUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import RevealButton from 'components/button/reveal_button';
import CardButtons from 'components/card/card_buttons';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardUserInput from "components/card/card_user_input";
import CardArrowBox from "components/card/card_arrow_box";
import MediaWithSupplement from "components/media/media_with_supplement";
import { pixify } from "@seedlang/utils/src";
import CardWordText from "components/card/card_word_text";

@observer
class WordComprehendCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent>
          {
            this.props.card.hasTargetVideoClip && !DeckUI.deck.pronunciationDeck &&
              <MediaSection
                card={this.props.card}
                cardState={this.props.cardState}
                showMedia
                borderTopRadius={10}
                autoPlay={this.props.inView}
              />
          }
          {
            this.props.card.hasTargetVideoClip && DeckUI.deck.pronunciationDeck &&
              <MediaWithSupplement
                card={this.props.card}
                cardState={this.props.cardState}
                autoPlay={this.props.inView}
                borderTopRadius={10}
                borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
                videoClip={ this.props.card.targetVideoClip }
                supplementVideoClip={this.props.card.supplementVideoClip}
                supplementImage={this.props.card.supplementImage}
                usingDataSaverHeader={AppUI.usingDataSaverHeader}
                supplementBottom={DeckUI.layout.supplementBottom}
                loopSupplement={DeckUI.user.loopSupplement}
                hideSupplement={!this.props.showDeckSupplement}
                maximizedWidth={pixify(DeckUI.layout.videoHeight)}
                minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
                supplementMaximized={this.props.supplementMaximized}
                loadVideo
                marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              />
          }
          {
            DeckUI.deck.pronunciationDeck &&
              <CardWordText
                showIfRevealed
                toggleToShowSources={!this.props.card?.word?.isPhoneme && !this.props.card?.word?.isGrapheme}
                hideSources
                hasUserInput
                card={this.props.card}
                cardState={this.props.cardState}
                sectionHeight={DeckUI.layout.contentHeight - DeckUI.layout.innerDeckWidth - DeckUI.layout.userInputHeight}
                showType={this.props.cardState.showType}
              />
          }
          {
            !DeckUI.deck.pronunciationDeck &&
            <>
              <CardSection
                background="#FFF"
                borderTopRadius={this.props.card.hasTargetVideoClip ? 0 : 10}
                numSections={this.props.card.hasTargetVideoClip ? 3 : 2}
                height={this.props.sectionHeight}
              >
                {
                  this.props.showExpandedTarget &&
                  <ExpandedTarget
                    card={this.props.card}
                    cardState={this.props.cardState}
                    height={DeckUI.vocabTextHeight}
                    word={this.props.card.word}
                    showVocabTriadIntro={this.props.showVocabTriadIntro}
                  />
                }
              </CardSection>
              <CardSection
                background="#CCC"
                fontSize={this.props.card.word.isNumber && this.props.card.word.digitSourceText ? "34px" : null}
                borderBottomRadius={this.props.card.hasTargetVideoClip && DeckUI.canRecordMedia ? 0 : 10}
                height={this.props.sectionHeight}
              >
                {
                  this.props.cardState.revealed && (!this.props.card.word.isNumber || !this.props.card.word.digitSourceText) &&
                    <CardSourceList
                      showEdit
                      word={this.props.card.word}
                      card={this.props.card}
                    />
                }
                {
                  this.props.cardState.revealed && this.props.card.word.isNumber && this.props.card.word.digitSourceText &&
                  <span>{this.props.card.word.formattedDigitSourceText}</span>
                }
              </CardSection>
            </>
          }
          {
            this.props.card.hasTargetVideoClip &&
              <CardUserInput
                autoPlay
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardArrowBox
            card={this.props.card}
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        {
          !this.props.cardState.revealed &&
            <CardButtons>
              <RevealButton
                card={this.props.card}
                userCard={this.props.userCard}
                cardState={this.props.cardState}
                disabled={!this.props.inView}
                submitOnEnter
                shortcut="1"
              />
            </CardButtons>
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
              onNextClick={this.props.onNextClick}
            />
        }
      </CardWrapper>
    );
  }
}

export default WordComprehendCardWrapper(WordComprehendCard);
