import React from 'react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

class Translation extends React.Component {

  render() {
    return (
      <div className="translation">
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.sentences.index',
              title: 'Sentences',
            },
            {
              link: 'builder.words.index',
              title: 'Words',
              params: {page: 1},
            },
            {
              link: 'builder.translation_sources.index',
              title: 'Translation Sources',
            },
            {
              link: 'builder.sources.index',
              title: 'Sources',
            },
            {
              link: 'builder.targets.index',
              title: 'Targets',
            },
            {
              link: 'builder.card_submissions.index',
              title: 'Card Submissions',
              params: {page: 1},
            },
            {
              link: 'builder.sentence_submissions.index',
              title: 'Sentence Submissions',
            },
            {
              link: 'builder.word_types.index',
              title: 'Word Types',
            },
            {
              link: 'builder.ai_services.index',
              title: 'AI Services',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Translation;
