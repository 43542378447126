import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { MembershipTypeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import MembershipTypeCreate from './membership_type_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';

const Note = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  margin-top: 10px;
`;

@observer
class SiteMembershipTypeIndex extends React.Component {

  componentDidMount() {
    this.loadMembershipTypes();
  }

  @autobind onDelete(id) {
    MembershipTypeStore.destroy({ids: {membershipTypeId: id}});
  }

  @autobind loadMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @computed get filteredMembershipTypes() {
    const siteId = Cookies.get('site_id');

    return MembershipTypeStore.indexData.filter((item) => item.siteId === siteId);
  }

  render() {
    return (
      <div className='builder-membership-type-index'>
        <div className='row' style={{ marginBottom: '10px' }}>
          <div className='col-xs-12'>
            <MembershipTypeCreate />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <table className="table-wrapper admin-table">
              <thead>
                <tr>
                  <th width="20" />
                  <th>Pos</th>
                  <th>Name</th>
                  <th>Slug</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.filteredMembershipTypes.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{ name: 'creator.groups.membership_types.edit', params: { groupId: this.props.params.groupId, membershipTypeId: item.id } }}
                            className="on-click"
                          >
                            <i className="fa fa-chevron-right fa-block" />
                          </Link>
                        </td>
                        <td>
                          <InPlaceText
                            id={item.id}
                            defaultValue={item.position}
                            model="membership_types"
                            field="position"
                            type="number"
                            step="1"
                            afterChange={this.loadMembershipTypes}
                          />
                        </td>
                        <td>
                          <div className="text">
                            {item.name}
                          </div>
                        </td>
                        <td>
                          <div className="text">
                            {item.slug}
                          </div>
                        </td>
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message="Delete this Payment Plan?"
                            className="fa-block right"
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <Paginator
              store={MembershipTypeStore}
            />
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <Note>
                Note: this page is only meant for setting up membership types. Please use the <a href={`/creator/groups/${this.props.params.groupId}/membership_groups`} className='underline'>Memberships</a> page to manage which membershp groups are currently visible on the site.  
                </Note>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SiteMembershipTypeIndex;
